<ng-container *ngIf="!state.error; else stateError">
  <ng-container *ngIf="!state.loading; else stateLoading">
    <div class="goals-individual-container">
      <div class="card">
        <!-- Actions -->
        <div class="top-actions" [class.actions-position-bottom]="state.editing" *ngIf="canEditGoal">
          <div class="top-actions-inner">

            <div class="top-action-divider"></div>

            <ng-container *ngIf="!goal.archived; else actionsArchived">
              <ng-container *ngIf="!goal.complete; else actionsComplete">
                <ng-container *ngIf="!state.editing; else actionsEditing">
                  <ng-container [ngTemplateOutlet]="actionsActive"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- Start editing and archive -->
            <ng-template #actionsActive>
              <div class="top-action-item top-action-yellow" (click)="startEditing()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="StartEditGoalIndividual"
                [tooltip]="eGoalsMessages.START_EDITING_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-pencil"></span>
                <span class="top-action-title" translate>{{eCommonMessages.EDIT}}</span>
              </div>

              <div class="top-action-divider"></div>

              <div class="top-action-item top-action-navy" (click)="archive()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="ArchiveGoalIndividual"
                [tooltip]="eGoalsMessages.ARCHIVE_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-archive"></span>
                <span class="top-action-title" translate>{{eCommonMessages.ARCHIVE}}</span>
              </div>
            </ng-template>

            <!-- Save and cancel edits -->
            <ng-template #actionsEditing>
              <div class="top-action-item top-action-red" (click)="cancelEdit()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="CancelEditGoalIndividual"
                [tooltip]="eCommonMessages.CANCEL_EDIT_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-times"></span>
                <span class="top-action-title" translate>{{eCommonMessages.CANCEL_EDIT}}</span>
              </div>

              <div class="top-action-divider"></div>

              <div class="top-action-item top-action-green-solid" (click)="submitEdits()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="SaveEditGoalIndividual"
                [tooltip]="eCommonMessages.SAVE_CHANGES_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-check"></span>
                <span class="top-action-title" translate>{{eCommonMessages.SAVE_CHANGES}}</span>
              </div>
            </ng-template>

            <!-- Unarchive and delete -->
            <ng-template #actionsArchived>
              <div class="top-action-item top-action-navy" (click)="unarchive()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="UnarchiveGoalIndividual"
                [tooltip]="eGoalsMessages.UNARCHIVE_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-folder-open"></span>
                <span class="top-action-title" translate>{{eCommonMessages.UNARCHIVE}}</span>
              </div>
            </ng-template>

            <!-- Activate and archive -->
            <ng-template #actionsComplete>
              <div class="top-action-item top-action-green" (click)="active()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="ActivateGoalIndividual"
                [tooltip]="eGoalsMessages.ACTIVATE_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-check"></span>
                <span class="top-action-title" translate>{{eGoalsMessages.ACTIVATE}}</span>
              </div>

              <div class="top-action-divider"></div>

              <div class="top-action-item top-action-navy" (click)="archive()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="ArchiveGoalIndividual"
                [tooltip]="eGoalsMessages.ARCHIVE_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-archive"></span>
                <span class="top-action-title" translate>{{eCommonMessages.ARCHIVE}}</span>
              </div>
            </ng-template>

            <ng-container *ngIf="!state.editing">
              <div class="top-action-divider"></div>

              <div class="top-action-item top-action-red" (click)="deleteGoal()" angularticsCategory="Goals"
                angulartics2On="click" angularticsAction="DeleteGoalIndividual"
                [tooltip]="eGoalsMessages.DELETE_TOOLTIP | translate" [triggers]="globals.tooltipTriggers"
                placement="top" container="body">
                <span class="fal fa-fw fa-trash"></span>
                <span class="top-action-title" translate>{{eCommonMessages.DELETE}}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Main header (Status, title progress) -->
        <div class="goal-header-container" [ngClass]="statusHighlightClass">
          <div class="row m-left-0 m-right-0">
            <div class="col-main-title">
              <div class="display-goal-title">
                <div class="goal-title frankli-form-group frankli-form-group-placeholder-shown"
                  [class.frankli-form-group-invalid]="state.editing && state.submitted && goalTitle.invalid">
                  <span class="icon-goal-title" [tooltip]="eGoalsMessages.OBJECTIVE | translate"
                    [triggers]="globals.tooltipTriggers" placement="bottom" container="body">
                    <span class="fal fa-fw fa-bullseye"></span>
                  </span>

                  <ng-container *ngIf="!state.editing; else templateEditingTitle">
                    <span class="goal-title-regular">{{goal.title}}</span>
                  </ng-container>
                  <ng-template #templateEditingTitle>
                    <input type="text" [placeholder]="eGoalsMessages.OBJECTIVE | translate"
                      class="form-control control-goal-title" rows="1" [formControl]="goalTitle" />
                  </ng-template>
                </div>

                <div class="frankli-form-group-alert" *ngIf="state.editing">
                  <ng-container *ngIf="state.editing && goalTitle.invalid">
                    <div *ngIf="goalTitle.hasError('maxlength')" translate>{{eCommonMessages.FORM_MAX_CHARACTER_VALUE |
                      translate: { maxValue: 255 } }}</div>
                    <ng-container *ngIf="state.editing && state.submitted && goalTitle.invalid">
                      <div *ngIf="goalTitle.hasError('required')" translate>
                        {{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_OBJECTIVE_REQUIRED}}</div>
                      <div *ngIf="goalTitle.hasError('minlength')" translate
                        [translateParams]="{ minValue: eGoalCreateValidationLimits.title.min }">
                        {{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-round-progress round-progress-z-index-fix">
              <div class="round-progress-container">
                <app-progress-bar-round [progress]="goal.complete ? 100 : goal.completionPercentage" [size]="70"
                  [fontSize]="20" [thickness]="5"></app-progress-bar-round>
              </div>
            </div>
          </div>
        </div>

        <hr class="divider-h" />

        <!-- Goal metadata -->
        <div class="container-metadata">
          <!-- <div class="primary-heading">
            <span translate>Goal details</span>
          </div> -->

          <div class="metadata-items-grid">
            <div class="display-goal-type">
              <div class="metadata-label">
                <span class="fal fa-fw fa-cog"></span>
                <span translate>{{eGoalsMessages.GOAL_TYPE}}</span>
              </div>

              <ng-container *ngIf="!state.editing; else templateEditingType">
                <div class="metadata-value">
                  <div class="display-icon-text">
                    <app-goal-type-display [type]="goal.type"></app-goal-type-display>
                    <div [ngSwitch]="goal.type">
                      <span *ngSwitchCase="eGoalType.PERSONAL_OPERATIONAL"
                        translate>{{eGoalsMessages.PERSONAL_OPERATIONAL}}</span>
                      <span *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL"
                        translate>{{eGoalsMessages.PERSONAL_DEVELOPMENTAL}}</span>
                      <span *ngSwitchCase="eGoalType.TEAM" translate>{{eGoalsMessages.TEAM_GOAL | translate: {
                        teamWording: globals.getTerminology(eTerminologyEntity.TEAM) } }}</span>
                      <span *ngSwitchCase="eGoalType.DEPARTMENT" translate>{{eGoalsMessages.DEPARTMENT_GOAL | translate:
                        { departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) } }}</span>
                      <span *ngSwitchCase="eGoalType.OFFICE_LOCATION"
                        [innerHTML]="eGoalsMessages.SITE_GOAL| translate:{ site: globals.getTerminology(eTerminologyEntity.SITE) }"></span>
                      <span *ngSwitchCase="eGoalType.COMPANY" translate>{{eCommonMessages.COMPANY}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-template #templateEditingType>
                <div class="frankli-form-group metadata-control-indent"
                  [tooltip]="goalType.disabled ? (eGoalsMessages.CANNOT_CHANGE_WHILE_ALIGNED_CHILDREN | translate) : ''"
                  placement="bottom" container="body">
                  <select class="form-control" [formControl]="goalType">
                    <option [ngValue]="null" *ngIf="goalType.value === null" translate>{{eCommonMessages.SELECT_PROMPT}}
                    </option>
                    <option [ngValue]="eGoalType.PERSONAL_OPERATIONAL" translate>{{eGoalsMessages.PERSONAL_OPERATIONAL}}
                    </option>
                    <option [ngValue]="eGoalType.PERSONAL_DEVELOPMENTAL" translate>
                      {{eGoalsMessages.PERSONAL_DEVELOPMENTAL}}</option>
                    <option [ngValue]="eGoalType.TEAM" translate>{{eGoalsMessages.TEAM_GOAL | translate: { teamWording:
                      globals.getTerminology(eTerminologyEntity.TEAM) } }}</option>
                    <option [ngValue]="eGoalType.DEPARTMENT" translate>{{eGoalsMessages.DEPARTMENT_GOAL | translate: {
                      departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) } }}</option>
                    <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_OFFICE_LOCATION)">
                      <option [ngValue]="eGoalType.OFFICE_LOCATION"
                        [innerHTML]="eGoalsMessages.SITE_GOAL| translate:{ site: globals.getTerminology(eTerminologyEntity.SITE) }"></option>
                    </ng-container>
                    <ng-container *ngIf="globals.hasRole(eRoleName.GOAL_COMPANY)">
                      <option [ngValue]="eGoalType.COMPANY" translate>{{eCommonMessages.COMPANY}}</option>
                    </ng-container>
                  </select>
                </div>
              </ng-template>

              <div class="frankli-form-group-alert">
                <ng-container *ngIf="state.editing && state.submitted && goalType.invalid">
                  <div *ngIf="goalType.hasError('required')" translate>
                    {{eGoalsMessages.GOAL_CREATE_TYPE_VALIDATION_REQUIRED}}</div>
                </ng-container>
              </div>
            </div>

            <div class="display-goal-created-date">
              <div class="metadata-label">
                <span class="fal fa-fw fa-plus"></span>
                <span translate>{{eCommonMessages.DATE_CREATED}}</span>
              </div>

              <div class="metadata-value">
                <span>{{(goal.creationDate | momentPipe: 'Do MMMM YYYY')}}</span>
              </div>

              <div class="frankli-form-group-alert"></div>
            </div>

            <div class="display-goal-end-date">
              <div class="metadata-label">
                <span class="fal fa-fw fa-clock"></span>
                <span translate>{{eGoalsMessages.DUE_DATE}}</span>
              </div>

              <ng-container *ngIf="!state.editing; else templateEditingDueDate">
                <div class="metadata-value">
                  <span class="d-inline-block">{{goal.endDate | momentPipe: 'Do MMMM YYYY' }}</span>
                  <span class="d-inline-block" *ngIf="dueDuration && dueDuration.length > 0">({{dueDuration}})</span>
                </div>
              </ng-container>
              <ng-template #templateEditingDueDate>
                <div class="frankli-form-group metadata-control-indent"
                  [class.frankli-form-group-invalid]="state.editing && state.submitted && goalEndDate.invalid">
                  <app-date-picker [formControl]="goalEndDate" [disableUntil]="minGoalDueDate"></app-date-picker>
                </div>
              </ng-template>

              <div class="frankli-form-group-alert">
                <ng-container *ngIf="state.editing && state.submitted && goalEndDate.invalid">
                  <div *ngIf="goalEndDate.hasError('required')" translate>
                    {{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_DUE_DATE_REQUIRED}}</div>
                  <div *ngIf="goalEndDate.hasError('mindate')" translate>{{eCommonMessages.CANNOT_BE_IN_THE_PAST}}</div>
                </ng-container>
              </div>
            </div>

            <div class="display-goal-visibility">
              <div class="metadata-label">
                <span class="fal fa-fw fa-lock"></span>
                <span translate>{{eGoalsMessages.VISIBILITY}}</span>
              </div>

              <ng-container *ngIf="!state.editing; else templateEditingVisibility">
                <div class="metadata-value">
                  <div class="display-icon-text">
                    <app-goal-visibility-display [visibility]="goal.visibility"></app-goal-visibility-display>
                    <ng-container [ngSwitch]="goal.visibility">
                      <span *ngSwitchCase="eGoalVisibility.PUBLIC" translate>{{eGoalsMessages.PUBLIC}}</span>
                      <span *ngSwitchCase="eGoalVisibility.PRIVATE" translate>{{eGoalsMessages.PRIVATE}}</span>
                      <span *ngSwitchDefault translate>{{eCommonMessages.UNKNOWN}}</span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-template #templateEditingVisibility>
                <div class="frankli-form-group metadata-control-indent"
                  [tooltip]="goalType.disabled ? (eGoalsMessages.CANNOT_CHANGE_WHILE_ALIGNED_CHILDREN | translate) : ''"
                  placement="bottom" container="body">
                  <app-button-group [formControl]="goalVisibility" [options]="formOptionsVisibility"
                    [consistentWidths]="true"></app-button-group>
                </div>
              </ng-template>

              <div class="frankli-form-group-alert">
                <ng-container *ngIf="state.editing && state.submitted && goalVisibility.invalid">
                  <div *ngIf="goalVisibility.hasError('required')" translate>
                    {{eGoalsMessages.GOAL_CREATE_DETAILS_VALIDATION_VISIBILITY_REQUIRED}}</div>
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.GOAL_PRIORITY)">
              <div class="display-goal-priority" *ngIf="(goal.priority !== eGoalPriority.NOT_SET) || (state.editing)">
                <div class="metadata-label">
                  <span class="fal fa-fw fa-exclamation-circle"></span>
                  <span translate>{{eGoalsMessages.PRIORITY}}</span>
                  <span *ngIf="state.editing">({{eCommonMessages.OPTIONAL | translate}})</span>
                </div>


                <ng-container *ngIf="!state.editing; else templateEditingPriority">
                  <div class="metadata-value">
                    <!-- <app-goal-priority-display [priority]="goal.priority"></app-goal-priority-display> -->

                    <div class="display-icon-text">
                      <app-goal-priority-display [priority]="goal.priority"></app-goal-priority-display>
                      <div [ngSwitch]="goal.priority">
                        <span *ngSwitchCase="eGoalPriority.P1" translate>{{eGoalsMessages.PRIORITY_P1_TEXT}}</span>
                        <span *ngSwitchCase="eGoalPriority.P2" translate>{{eGoalsMessages.PRIORITY_P2_TEXT}}</span>
                        <span *ngSwitchCase="eGoalPriority.P3" translate>{{eGoalsMessages.PRIORITY_P3_TEXT}}</span>
                        <span *ngSwitchCase="eGoalPriority.P4" translate>{{eGoalsMessages.PRIORITY_P4_TEXT}}</span>
                        <span *ngSwitchCase="eGoalPriority.P5" translate>{{eGoalsMessages.PRIORITY_P5_TEXT}}</span>
                        <span *ngSwitchCase="eGoalPriority.NOT_SET" translate>{{eCommonMessages.NOT_SET}}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #templateEditingPriority>
                  <div class="frankli-form-group metadata-control-indent">
                    <select [formControl]="goalPriority" class="form-control">
                      <option [ngValue]="eGoalPriority.NOT_SET" translate>
                        <span translate>{{eCommonMessages.NOT_SET}}</span>
                      </option>
                      <option [ngValue]="eGoalPriority.P1" translate>
                        <span translate>{{eGoalsMessages.PRIORITY_P1}}</span>
                        <span>-</span>
                        <span translate>{{eGoalsMessages.PRIORITY_P1_TEXT}}</span>
                      </option>
                      <option [ngValue]="eGoalPriority.P2" translate>
                        <span translate>{{eGoalsMessages.PRIORITY_P2}}</span>
                        <span>-</span>
                        <span translate>{{eGoalsMessages.PRIORITY_P2_TEXT}}</span>
                      </option>
                      <option [ngValue]="eGoalPriority.P3" translate>
                        <span translate>{{eGoalsMessages.PRIORITY_P3}}</span>
                        <span>-</span>
                        <span translate>{{eGoalsMessages.PRIORITY_P3_TEXT}}</span>
                      </option>
                      <option [ngValue]="eGoalPriority.P4" translate>
                        <span translate>{{eGoalsMessages.PRIORITY_P4}}</span>
                        <span>-</span>
                        <span translate>{{eGoalsMessages.PRIORITY_P4_TEXT}}</span>
                      </option>
                      <option [ngValue]="eGoalPriority.P5" translate>
                        <span translate>{{eGoalsMessages.PRIORITY_P5}}</span>
                        <span>-</span>
                        <span translate>{{eGoalsMessages.PRIORITY_P5_TEXT}}</span>
                      </option>
                    </select>
                  </div>
                </ng-template>

                <div class="frankli-form-group-alert"></div>
              </div>
            </ng-container>

            <div class="display-goal-department"
              *ngIf="(goal.type === eGoalType.DEPARTMENT) || (state.editing && goalType.value === eGoalType.DEPARTMENT)">
              <div class="metadata-label">
                <span class="fal fa-fw fa-briefcase"></span>
                <span>{{globals.getTerminology(eTerminologyEntity.DEPARTMENT) | titlecase}}</span>
              </div>

              <ng-container *ngIf="!state.editing; else templateEditingDepartment">
                <div class="metadata-value">
                  <ng-container *ngIf="goal.department; else noDepartment">
                    <span>{{goal.department.name}}</span>
                  </ng-container>
                  <ng-template #noDepartment>
                    <span translate>{{eCommonMessages.NOT_SET}}</span>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #templateEditingDepartment>
                <div class="frankli-form-group metadata-control-indent"
                  [class.frankli-form-group-invalid]="state.editing && state.submitted && goalDepartment.invalid">
                  <select class="form-control" [formControl]="goalDepartment">
                    <ng-container *ngIf="goalDepartment.value === null">
                      <option [ngValue]="null" disabled translate>{{eCommonMessages.SELECT_PROMPT}}</option>
                    </ng-container>
                    <ng-container *ngFor="let department of formOptionsDepartment">
                      <option [ngValue]="department.id">{{department.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </ng-template>

              <div class="frankli-form-group-alert">
                <ng-container *ngIf="state.editing && state.submitted && goalDepartment.invalid">
                  <div *ngIf="goalDepartment.hasError('required')" translate
                    [translateParams]="{ departmentWording: globals.getTerminology(eTerminologyEntity.DEPARTMENT) }">
                    {{eGoalsMessages.GOAL_CREATE_DEPARTMENT_VALIDATION_REQUIRED}}</div>
                </ng-container>
              </div>
            </div>

            <div class="display-goal-site"
              *ngIf="(goal.type === eGoalType.OFFICE_LOCATION) || (state.editing && goalType.value === eGoalType.OFFICE_LOCATION)">
              <div class="metadata-label">
                <span class="fal fa-fw fa-building"></span>
                <span translate>{{globals.getTerminology(eTerminologyEntity.SITE) | titlecase}}</span>
              </div>

              <ng-container *ngIf="!state.editing; else templateEditingSite">
                <div class="metadata-value">
                  <ng-container *ngIf="goal.officeLocation; else noSite">
                    <span>{{goal.officeLocation.name}}</span>
                  </ng-container>
                  <ng-template #noSite>
                    <span translate>{{eCommonMessages.NOT_SET}}</span>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #templateEditingSite>
                <div class="frankli-form-group metadata-control-indent"
                  [class.frankli-form-group-invalid]="state.editing && state.submitted && goalSite.invalid">
                  <select class="form-control" [formControl]="goalSite">
                    <option [ngValue]="null" disabled *ngIf="goalSite.value === null" translate>
                      {{eCommonMessages.SELECT_PROMPT}}</option>
                    <ng-container *ngFor="let officeLocation of formOptionsSite">
                      <option [ngValue]="officeLocation.id">{{officeLocation.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </ng-template>

              <div class="frankli-form-group-alert">
                <ng-container *ngIf="state.editing && state.submitted && goalSite.invalid">
                  <div *ngIf="goalSite.hasError('required')"
                    [innerHTML]="eGoalsMessages.GOAL_CREATE_SITE_VALIDATION_REQUIRED| translate:{ site: globals.company.globals.getTerminology(eTerminologyEntity.SITE) }">
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="display-goal-development-needs"
            *ngIf="(goal.type === eGoalType.PERSONAL_DEVELOPMENTAL) || (state.editing && goalType.value === eGoalType.PERSONAL_DEVELOPMENTAL)">
            <div class="metadata-label">
              <span class="fal fa-fw fa-graduation-cap"></span>
              <span translate>{{eGoalsMessages.DEVELOPMENT_NEEDS}}</span>
            </div>

            <ng-container *ngIf="!state.editing; else templateEditingDevelopmentNeeds;">
              <div class="metadata-value">
                <ng-container
                  *ngIf="(goal.developmentNeeds && goal.developmentNeeds.length > 0); else noDevelopmentNeeds;">
                  <span>{{goal.developmentNeeds}}</span>
                </ng-container>
                <ng-template #noDevelopmentNeeds>
                  <span class="empty-value" translate>{{eCommonMessages.NOT_SET}}</span>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #templateEditingDevelopmentNeeds>
              <div class="frankli-form-group metadata-control-indent">
                <!-- TODO: make this a component w/ word count built in (also add word count here) -->
                <textarea rows="3" [placeholder]="eGoalsMessages.DEVELOPMENT_NEEDS_PLACEHOLDER | translate"
                  [formControl]="goalDevelopmentNeeds" class="form-control noresize"></textarea>
              </div>
            </ng-template>

            <div class="frankli-form-group-alert">
              <div *ngIf="goalDevelopmentNeeds.hasError('minlength')" translate
                [translateParams]="{ minValue: eGoalCreateValidationLimits.title.min }">
                {{eCommonMessages.FORM_MIN_CHARACTER_VALUE}}</div>
              <div *ngIf="goalDevelopmentNeeds.hasError('maxlength')" translate
                [translateParams]="{ minValue: eGoalCreateValidationLimits.title.max }">
                {{eCommonMessages.FORM_MAX_CHARACTER_VALUE}}</div>
            </div>
          </div>

          <div class="display-goal-owners">
            <div class="metadata-label">
              <span class="fal fa-fw fa-user-friends"></span>
              <span translate>{{eGoalsMessages.OWNERS}}</span>
            </div>

            <ng-container *ngIf="!state.editing; else templateEditingOwners">
              <div class="metadata-value">
                <app-person-picture-list [users]="goal.owners" [maxListed]="5"></app-person-picture-list>
              </div>
            </ng-container>
            <ng-template #templateEditingOwners>
              <div class="frankli-form-group metadata-control-indent">
                <app-user-picker-multiple [formControl]="goalOwners"></app-user-picker-multiple>
              </div>
            </ng-template>

            <div class="frankli-form-group-alert">
              <ng-container *ngIf="state.editing && state.submitted && goalOwners.invalid">
                <div *ngIf="goalOwners.hasError('minlength')" translate>
                  {{eGoalsMessages.GOAL_CREATE_OWNERS_VALIDATION_MINIMUM}}</div>
              </ng-container>
            </div>
          </div>

          <div class="display-goal-tags" *ngIf="(goal.tags && goal.tags.length > 0) || (state.editing)">
            <div class="metadata-label">
              <span class="fal fa-fw fa-tag"></span>
              <span translate>{{eGoalsMessages.TAGS}}</span>
            </div>

            <ng-container *ngIf="!state.editing; else templateEditingTags">
              <div class="metadata-value">
                <div class="container-tag-items">
                  <ng-container *ngFor="let tag of goal.tags">
                    <div class="card no-margin">
                      <app-tag-display [tag]="tag"></app-tag-display>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #templateEditingTags>
              <div class="frankli-form-group metadata-control-indent">
                <app-tag-picker [formControl]="goalTags" [canSelectMultiple]="true"></app-tag-picker>
              </div>
            </ng-template>

            <div class="frankli-form-group-alert"></div>
          </div>

          <div class="display-goal-aligned-parent"
            *ngIf="(alignedParent || (state.editing && goalType.value !== eGoalType.COMPANY))">
            <div class="metadata-label">
              <span class="fal fa-fw fa-diagram-previous"></span>
              <span translate>{{eGoalsMessages.ALIGNED_PARENT}}</span>
            </div>

            <ng-container *ngIf="!state.editing; else templateEditingAlignedParent;">
              <div class="metadata-value clickable" *ngIf="alignedParent" (click)="navigateToGoal(alignedParent)">
                <app-goal-display [goal]="alignedParent"></app-goal-display>
              </div>
            </ng-container>
            <ng-template #templateEditingAlignedParent>
              <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown metadata-control-indent"
                *ngIf="goalType.value !== eGoalType.COMPANY">
                <app-goal-picker [formControl]="goalAlignment" [allowedGoalTypes]="validGoalTypes"></app-goal-picker>
              </div>
            </ng-template>

            <div class="frankli-form-group-alert"></div>
          </div>

          <div class="display-goal-aligned-children" *ngIf="!state.editing">
            <div>
              <div class="metadata-label">
                <span class="fal fa-fw fa-diagram-next"></span>
                <span translate>{{eGoalsMessages.ALIGNED_CHILDREN}}</span>
              </div>
            </div>
            <ng-container *ngIf="!state.editing; else templateEditingAlignedChildren;">
              <div class="metadata-value" [ngClass]="{'border': alignedChildren.length > 1}" id="goals-list">
                <ng-container *ngFor="let childGoal of alignedChildren; index as index;">
                  <div class="child-goal-item" (click)="navigateToGoal(childGoal)">
                    <app-goal-display [goal]="childGoal"></app-goal-display>
                  </div>
                </ng-container>
              </div>

              <!-- Create goal button -->
              <div class="text-center p-top-15">
                <app-button [type]="eButtonType.GREEN_INVERTED" (onClick)="createAlignedGoal()">Create aligned goal</app-button>
              </div>

            </ng-container>
            <ng-template #templateEditingAlignedChildren>
              <!-- You can't edit aligned children but have left this here incase we decide to add a way to later -->
            </ng-template>

            <div class="frankli-form-group-alert"></div>
          </div>
        </div>

        <hr class="divider-h" />

        <!-- Key Results and Goal Update -->
        <!-- TODO: make this a component or multiple components -->
        <div class="p-top-20 p-bottom-20 p-left-20 p-right-20">
          <!-- Display key results (non-owner, archived goal and complete goal) -->
          <ng-container *ngIf="!state.editing; else editingKeyResults">
            <!-- Goal Update -->
            <ng-container *ngIf="!goal.complete && !goal.archived && canEditGoal; else displayKeyResults">
              <div class="primary-heading">
                <span translate>{{eGoalsMessages.UPDATE_PROGRESS}}</span>
              </div>
              <app-goals-individual-update-component [goal]="goal" [showControls]="canEditGoal"
                [editing]="state.editing" (refresh)="refresh($event)"
                (completeEmit)="complete()"></app-goals-individual-update-component>
            </ng-container>

            <!-- Key results display -->
            <ng-template #displayKeyResults>
              <div class="display-goal-key-results">
                <div class="metadata-label">
                  <span class="fal fa-fw fa-list"></span>
                  <span translate>{{eGoalsMessages.KEY_RESULTS}}</span>
                </div>

                <app-key-results-list [keyResults]="goal.keyResults"></app-key-results-list>
              </div>
            </ng-template>
          </ng-container>

          <ng-template #editingKeyResults>
            <!-- Editing key results -->
            <div class="kr-edit">
              <div class="metadata-label">
                <span class="fal fa-fw fa-list"></span>
                <span translate>{{eGoalsMessages.KEY_RESULTS}}</span>
              </div>

              <!-- Edit warning -->
              <div class="p-left-10 p-right-10 p-bottom-10" *ngIf="editingResultsWarningVisible">
                <div class="kr-edit-warning">
                  <div class="icon-hide" [tooltip]="'hide this warning'" (click)="editingResultsWarningVisible = false">
                    <span class="fal fa-fw fa-times expand-on-hover"></span>
                  </div>
                  <div class="warning-text">
                    <span translate>{{eGoalsMessages.EDIT_KEY_RESULT_WARNING}}</span>
                    <br />
                    <span translate>{{eGoalsMessages.EDIT_KEY_RESULT_UPDATE_PROMPT}}</span>
                  </div>
                </div>
              </div>

              <!-- Table -->
              <div class="p-left-10 p-right-10 p-top-10 p-bottom-10">
                <!-- Table headings -->
                <div class="row m-left-0 m-right-0 kr-headings">
                  <div class="col-kr-title">
                    <span translate>{{eGoalsMessages.KEY_RESULT}}</span>
                  </div>
                  <div class="col-kr-type">
                    <span translate>{{eGoalsMessages.TYPE}}</span>
                  </div>
                  <div class="col-kr-start">
                    <span translate>{{eGoalsMessages.START_VALUE}}</span>
                  </div>
                  <div class="col-kr-slash"></div>
                  <div class="col-kr-goal">
                    <span translate>{{eGoalsMessages.GOAL_VALUE}}</span>
                  </div>
                  <div class="col-kr-unit">
                    <span translate>{{eGoalsMessages.UNIT}}</span>
                    <span>({{eCommonMessages.OPTIONAL | translate}})</span>
                  </div>
                  <div class="col-kr-unit-placement">
                    <span translate>{{eGoalsMessages.UNIT_PLACEMENT}}</span>
                  </div>
                  <div class="col-kr-end-date">
                    <span translate>{{eGoalsMessages.DUE_DATE}}</span>
                  </div>
                </div>

                <!-- Table body -->
                <div class="key-result-list" cdkDropList #editKeyResultList="cdkDropList"
                  [cdkDropListData]="goalKeyResultsList" (cdkDropListDropped)="onDropKeyResult($event)">
                  <ng-container *ngFor="let keyResult of goalKeyResultsList; index as index">
                    <div class="insert-result-row" (click)="insertKeyResultAtIndex(index)">
                      <span class="insert-result-icon">
                        <span class="fal fa-fw fa-plus"></span>
                      </span>
                      <span class="insert-result-text" translate>{{eGoalsMessages.INSERT_KEY_RESULT}}</span>
                    </div>

                    <div class="kr-item" cdkDrag [cdkDragData]="keyResult">
                      <div class="row m-left-0 m-right-0">
                        <div class="col-kr-drag-handle" cdkDragHandle>
                          <div class="drag-handle-icon">
                            <span class="fal fa-fw fa-ellipsis-v drag-handle"></span>
                          </div>
                        </div>
                        <div class="col-kr-edit-form">
                          <div class="kr-index">
                            <span translate>{{eGoalsMessages.KEY_RESULT}}</span>
                            <span>{{index + 1}}</span>
                          </div>
                          <!-- TODO: Make each row use a component -->
                          <div class="row kr-row">
                            <div class="col-kr-data">
                              <div class="row m-left-0 m-right-0">
                                <div class="col-kr-title">
                                  <div class="frankli-form-group frankli-form-group-placeholder-shown"
                                    [class.frankli-form-group-filled]="keyResult.controls.result.value"
                                    [class.frankli-form-group-invalid]="state.editing && state.submitted && keyResult.controls.result.invalid">
                                    <input class="form-control" type="text" placeholder="Name"
                                      [formControl]="keyResult.controls.result" />
                                    <div class="frankli-form-group-alert">
                                      <ng-container
                                        *ngIf="state.editing && state.submitted && keyResult.controls.result.invalid">
                                        <div *ngIf="keyResult.controls.result.hasError('required')" translate>
                                          {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_RESULT_REQUIRED}}</div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-kr-type">
                                  <div
                                    class="frankli-form-group frankli-form-group-placeholder-shown frankli-form-group-filled"
                                    [class.frankli-form-group-invalid]="state.editing && state.submitted && keyResult.controls.type.invalid">
                                    <select class="form-control" [formControl]="keyResult.controls.type">
                                      <option [ngValue]="null" *ngIf="keyResult.controls.type.value === null" translate>
                                        {{eCommonMessages.SELECT_PROMPT}}</option>
                                      <option [ngValue]="eGoalKeyResultType.BINARY" translate>
                                        {{eGoalsMessages.COMPLETE_INCOMPLETE}}</option>
                                      <option [ngValue]="eGoalKeyResultType.NUMERIC" translate>
                                        {{eGoalsMessages.NUMERIC}}</option>
                                    </select>
                                    <div class="frankli-form-group-alert">
                                      <ng-container
                                        *ngIf="state.editing && state.submitted && keyResult.controls.type.invalid">
                                        <div *ngIf="keyResult.controls.type.hasError('required')" translate>
                                          {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_MEASURE_TYPE_REQUIRED}}
                                        </div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>

                                <ng-container [ngSwitch]="keyResult.controls.type.value">
                                  <!-- Incomplete / Complete -->
                                  <ng-container *ngSwitchCase="eGoalKeyResultType.BINARY">
                                    <!-- We don't need to allow any configuration for BINARY so no content -->
                                    <div class="col-kr-start"></div>
                                    <div class="col-kr-slash"></div>
                                    <div class="col-kr-goal"></div>
                                    <div class="col-kr-unit"></div>
                                    <div class="col-kr-unit-placement"></div>
                                  </ng-container>

                                  <!-- Other Current / Goal Values -->
                                  <ng-container *ngSwitchCase="eGoalKeyResultType.NUMERIC">
                                    <div class="col-kr-start">
                                      <div class="frankli-form-group frankli-form-group-placeholder-shown"
                                        [class.frankli-form-group-filled]="keyResult.controls.measureStartValue.value"
                                        [class.frankli-form-group-invalid]="keyResult.controls.measureStartValue.invalid && state.editing && state.submitted">
                                        <input class="form-control" id="start-value"
                                          [formControl]="keyResult.controls.measureStartValue"
                                          [placeholder]="eGoalsMessages.START_VALUE | translate" />
                                        <div class="frankli-form-group-alert">
                                          <ng-container
                                            *ngIf="state.editing && state.submitted && keyResult.controls.measureStartValue.invalid">
                                            <div *ngIf="keyResult.controls.measureStartValue.hasError('required')"
                                              translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_REQUIRED}}
                                            </div>
                                            <div *ngIf="keyResult.hasError('goalSetToEqual')" translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_START_EQUALS_GOAL}}
                                            </div>
                                            <div
                                              *ngIf="keyResult.controls.measureStartValue.hasError('forbiddenCharacter')"
                                              translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_START_VALUE_FORBIDDEN_CHARACTER}}
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-kr-slash">
                                      <span>/</span>
                                    </div>

                                    <div class="col-kr-goal">
                                      <div class="frankli-form-group frankli-form-group-placeholder-shown"
                                        [class.frankli-form-group-filled]="keyResult.controls.measureGoalValue.value"
                                        [class.frankli-form-group-invalid]="keyResult.controls.measureGoalValue.invalid && state.editing && state.submitted">
                                        <input class="form-control" id="goal-value"
                                          [formControl]="keyResult.controls.measureGoalValue"
                                          [placeholder]="eGoalsMessages.GOAL_VALUE | translate" />
                                        <div class="frankli-form-group-alert">
                                          <ng-container
                                            *ngIf="state.editing && state.submitted && keyResult.controls.measureGoalValue.invalid">
                                            <div *ngIf="keyResult.controls.measureGoalValue.hasError('required')"
                                              translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_REQUIRED}}
                                            </div>
                                            <div
                                              *ngIf="keyResult.controls.measureGoalValue.hasError('forbiddenCharacter')"
                                              translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_GOAL_VALUE_FORBIDDEN_CHARACTER}}
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-kr-unit">
                                      <div class="frankli-form-group frankli-form-group-placeholder-shown"
                                        [class.frankli-form-group-filled]="keyResult.controls.measureUnit.value"
                                        [class.frankli-form-group-invalid]="keyResult.controls.measureUnit.invalid && state.editing && state.submitted">
                                        <input class="form-control" id="start-value"
                                          [formControl]="keyResult.controls.measureUnit"
                                          [placeholder]="eGoalsMessages.UNIT | translate" />
                                        <div class="frankli-form-group-alert"></div>
                                      </div>
                                    </div>

                                    <div class="col-kr-unit-placement">
                                      <div
                                        class="frankli-form-group frankli-form-group-placeholder-shown frankli-form-group-filled"
                                        *ngIf="keyResult.controls.measureUnit.value.trimStart()"
                                        [class.frankli-form-group-invalid]="keyResult.controls.measureUnitPlacement.invalid && state.editing && state.submitted">
                                        <select class="form-control"
                                          [formControl]="keyResult.controls.measureUnitPlacement">
                                          <option [ngValue]="eGoalKeyResultMeasureUnitPlacement.BEFORE" translate>
                                            {{eGoalsMessages.BEFORE}}</option>
                                          <option [ngValue]="eGoalKeyResultMeasureUnitPlacement.AFTER" translate>
                                            {{eGoalsMessages.AFTER}}</option>
                                        </select>
                                        <div class="frankli-form-group-alert">
                                          <ng-container
                                            *ngIf="state.editing && state.submitted && keyResult.controls.measureUnitPlacement.invalid">
                                            <div *ngIf="keyResult.controls.measureUnitPlacement.hasError('required')"
                                              translate>
                                              {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_UNIT_PLACEMENT_REQUIRED}}
                                            </div>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngSwitchDefault>
                                    <div class="col-kr-start"></div>
                                    <div class="col-kr-slash"></div>
                                    <div class="col-kr-goal"></div>
                                    <div class="col-kr-unit"></div>
                                    <div class="col-kr-unit-placement"></div>
                                  </ng-container>
                                </ng-container>

                                <div class="col-kr-end-date">
                                  <div class="frankli-form-group frankli-form-group-placeholder-shown"
                                    [class.frankli-form-group-filled]="keyResult.controls.endDate.value"
                                    [class.frankli-form-group-invalid]="state.editing && state.submitted && keyResult.controls.endDate.invalid">
                                    <app-date-picker [formControl]="keyResult.controls.endDate"
                                      [placeholder]="eGoalsMessages.DUE_DATE | translate" [alignSelectorRight]="true"
                                      [disableUntil]="minGoalDueDate"
                                      [disableSince]="maxLinkedItemDate"></app-date-picker>
                                    <div class="frankli-form-group-alert">
                                      <ng-container
                                        *ngIf="state.editing && state.submitted && keyResult.controls.endDate.invalid">
                                        <!-- <div *ngIf="keyResult.controls.endDate.hasError('mindate')" translate>{{eCommonMessages.CANNOT_BE_IN_THE_PAST}}</div> -->
                                        <div *ngIf="keyResult.controls.endDate.hasError('maxdate')" translate>
                                          {{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_VALIDATION_DUE_DATE_MAX}}</div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-kr-actions">
                              <div class="d-inline-block" (click)="removeKeyResultAtIndex(index)"
                                [tooltip]="eGoalsMessages.GOAL_CREATE_KEY_RESULTS_REMOVE_KEY_RESULT | translate"
                                [triggers]="globals.tooltipTriggers" placement="top" container="body">
                                <app-table-action-icon [icon]="'fa-times'"
                                  [hoverColor]="eIconHoverColor.RED"></app-table-action-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <!-- Action buttons -->
                <div class="text-center" *ngIf="state.editing">
                  <app-button [type]="eButtonType.GREEN_INVERTED"
                    (onClick)="insertKeyResultAtIndex(goalKeyResultsList.length)">
                    <span translate>{{eGoalsMessages.GOAL_CREATE_KEY_RESULTS_ADD_KEY_RESULT}}</span>
                  </app-button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- Tasks linked to this goal -->
      <ng-container *ngIf="!state.editing">
        <div class="card">
          <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.TODOS)">
            <div class="p-top-20 p-bottom-20 p-left-20 p-right-20">
              <div class="primary-heading">
                <span translate>{{eGoalsMessages.TASKS}}</span>
              </div>

              <app-goals-individual-tasks [goal]="goal"></app-goals-individual-tasks>

              <div class="frankli-form-group-alert"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Activity -->
      <ng-container *ngIf="!state.editing">
        <app-goals-individual-activity-component [goal]="goal"
          (refresh)="refresh($event)"></app-goals-individual-activity-component>
      </ng-container>
    </div>


    <!-- Create goal modal -->
    <ng-container *ngIf="state.creating">
      <app-modal [showCloseButton]="false" [minWidth]="'200px'" [maxWidth]="'900px'" [width]="'80%'" [sidePanel]="true"
        [confirmClosing]="goalCreateComponent.formChanged" [confirmCloseModalSettings]="confirmCloseSettings"
        (onConfirmCloseModalResponse)="onCloseCreateResponse($event)" #userGoalCreateModal>
        <app-goal-create [initGoalType]="createType" [initGoalAlignmentId]="goalId" [modal]="modalCreate" (requestCloseModal)="hideCreateModal()" #goalCreateComponent></app-goal-create>
      </app-modal>
    </ng-container>

  </ng-container>
</ng-container>

<!-- #region - ALTERNATIVE PAGE STATES -->
<!-- LOADING -->
<ng-template #stateLoading>
  <div class="card">
    <app-loading-state [showMessage]="false" [height]="'calc(100vh - 150px)'"></app-loading-state>
  </div>
</ng-template>

<!-- ERROR -->
<ng-template #stateError>
  <div class="card">
    <app-error-state [height]="'calc(100vh - 200px)'" [message]="state.errorMessage"></app-error-state>
  </div>
</ng-template>
<!-- #endregion -->