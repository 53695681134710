<div class="ticketing-sidebar-container">
  <ng-container *ngIf="!state.loading">
    <div class="sidebar-content-title">
      <span>{{subjectUser.firstName || "User"}}'s stats</span>
      <!-- <span>Ticketing stats</span> -->
      <!-- This image takes a bit long to load in, we shouldn't delay anything while waiting for it -->
      <ng-container *ngIf="integration != undefined">
        <img [src]="integration.image" />
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!state.loading; else stateLoading">
    <div class="filters">
      <span class="filter-label">Tickets between:</span>
      <app-date-range-picker class="date-range-picker" [formControl]="controlDateRange" [name]="'dateperiod'"
        [maxSpan]="{years: 1}" [linkedCalendars]="false" [alwaysShowCalendars]="true"
        [openPickerEvent]="openDatepickerEvent" [ranges]="dateRangePickerPresets"></app-date-range-picker>
    </div>
    <ng-container *ngIf="hasData; else hasNoData">
      <ng-container *ngIf="stats.hasStats; else hasNoStats">
        <!-- Data Output -->
        <div class="metric-list">
          <div class="metric-item" (click)="openTicketModal(eTicketStatus.OPEN)">
            <div class="metric-item-top">
              <div class="metric-value">{{stats.openTickets}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-ticket"></span>
              </div>
              <div class="metric-title">Open tickets</div>
            </div>

          </div>
          <div class="metric-item" (click)="openTicketModal(eTicketStatus.CLOSED)">
            <div class="metric-item-top">
              <div class="metric-value">{{stats.closedTickets}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-ticket"></span>
              </div>
            </div>
            <div class="metric-title">Closed Tickets</div>
          </div>
          <div class="metric-item" (click)="openTicketModal(eTicketStatus.ALL)">
            <div class="metric-item-top">
              <div class="metric-value">{{stats.totalTickets}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-ticket"></span>
              </div>
            </div>
            <div class="metric-title">Total tickets</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #hasNoStats>
      <div class="p-top-15 p-bottom-15 text-center">
        <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
        <br />
        <span>
          {{subjectUser.firstName}} has no tickets for the selected period.
        </span>
      </div>
    </ng-template>
    <ng-template #hasNoData>
      <div class="p-top-15 p-bottom-15 text-center">
        <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
        <br />
        <span>No ticketing data was found.<br>This could be because {{subjectUser.firstName || "this user"}}'s data
          hasn't synced yet <br>
          or because {{subjectUser.firstName || "this user"}} doesn't have a ticketing account in your company.</span>
      </div>
    </ng-template>
  </ng-container>

  <!-- #region - Alt states -->
  <!-- TODO: Use component -->
  <ng-template #stateLoading>
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>

  <!-- TODO: Use component -->
  <ng-template #stateError>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br />
      <span>An error occurred loading this information</span>
    </div>
  </ng-template>
  <!-- #endregion -->
</div>

<app-modal #modal>
  <ng-container *ngIf="!modalState.loading; else modalStateLoading">
    <div class="modal-title">
      {{subjectUser.firstName || "User"}}'s tickets
    </div>
    <div class="data-list">
      <div class="list-item" *ngFor="let ticket of tickets">
        <div class="item-title">{{ticket.ticketTitle || "No title"}}</div>
        <div class="item-info">
          <div class="item-prop">
            <span class="item-prop-title">Priority</span>: {{ticket.priority | titlecase }}
          </div>
          <div class="item-prop">
            <span class="item-prop-title">Status</span>: {{ticket.status | titlecase }}
          </div>
          <div class="item-prop">
            <span class="item-prop-title">Information</span>:
            <a [href]="ticket.ticketURL" class="item-prop" target="_blank">View ticket</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #modalStateLoading>
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>
</app-modal>