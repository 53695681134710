import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { richLinkRemoveLinks, recursiveAddLinks } from '@app/shared/tinymce/plugins/richLink.plugin';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { environment } from '../../../../environments/environment';
import { Globals } from '@app/shared/globals/globals';
import { FRANKLI_TINYMCE_ICONS } from 'assets/tinymce/frankli-icons/icons';
import { RICH_LINK_PLUGIN } from '@app/shared/tinymce/plugins/richLink.plugin';

declare const tinymce: any;

export const TOOLBAR_DEFAULTS_DESKTOP = 'undo redo | bold italic | alignleft aligncenter | bullist numlist checklist | btnFrankliLink ';
export const PLUGIN_DEFAULTS_DESKTOP = 'lists, checklist, link, image, table, frankliRichLink, anchor, autolink, codesample, help, tinymcespellchecker, powerpaste, fullscreen, autosave, autoresize';

export const TOOLBAR_DEFAULTS_MOBILE = 'formatselect | bold italic underline | alignleft aligncenter | bullist numlist checklist | btnFrankliLink';
export const PLUGIN_DEFAULTS_MOBILE = 'lists, checklist, link, autolink, frankliRichLink, tinymcespellchecker, powerpaste, autosave, autoresize';
export const MENUBAR_DEFAULTS_MOBILE = false;

@Component({
  selector: 'app-editor-response',
  templateUrl: './editor-response.component.html',
  styleUrls: ['./editor-response.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EditorResponseComponent,
      multi: true,
    },
  ],
})
export class EditorResponseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() disabled = false;
  @Input() display = false;
  @Input() resize: boolean;
  @Input() height: number | string;
  // Maximum / hard max limit (default 10,000).
  @Input() maxCharacters = 10000;
  @Input() hardMax: 10000;

  @Input() inline: boolean;

  @Output() autoEvent: EventEmitter<boolean>;
  @Input() wordcountChanged: EventEmitter<number>;

  @Input() toolbar: string | boolean;
  @Input() toolbarMobile: string | boolean;
  @Input() menubar: string | boolean;
  @Input() menubarMobile: string | boolean;
  @Input() plugins: string;
  @Input() pluginsMobile: string;
  @Input() contextMenu: string;
  @Input() columnIndent: boolean;
  @Input() showErrorBorder: boolean;
  @Input() enforceCharacterLimit: boolean;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() id: string;

  wordcount: number;
  warnWordcount: boolean;
  cachedValue: string;

  editor: any | null;
  _value: string;
  loading: boolean;
  firstInitRun: boolean;
  doRichLinkParsing: boolean;
  dynamicMaxCharacters = 10000;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): string {
    return this._value;
  }
  set value(val: string) {
    if (this._value !== val && !this.disabled) {
      this.cachedValue = val;
      this._value = val;
      this.onChange(val);
      this.autoEvent.emit(true);
    }
  }

  public editorConfig!: EditorComponent['init'];
  private localStorageKey = 'editorDynamicMax';
  private pasteProcessing = false;

  constructor(private cdRef: ChangeDetectorRef, public globals: Globals) {
    this._value = '';
    this.editor = null;
    this.resize = false;
    this.warnWordcount = false;
    this.inline = false;
    this.columnIndent = true;
    this.showErrorBorder = false;
    this.autofocus = false;
    this.enforceCharacterLimit = true;
    this.doRichLinkParsing = false;

    this.loading = false;
    this.firstInitRun = false;

    this.autoEvent = new EventEmitter<boolean>();
    this.wordcountChanged = new EventEmitter<number>();

    this.wordcount = 0;
    this.height = 300;
    this.cachedValue = '';
    this.menubar = undefined!;
    this.menubarMobile = undefined!;
    this.plugins = undefined!;
    this.pluginsMobile = undefined!;
    this.toolbar = undefined!;
    this.toolbarMobile = undefined!;
    this.placeholder = '';
    this.id = 'rich-editor';
  }

  ngOnInit() {
    if (tinymce && tinymce.PluginManager && tinymce.IconManager) {
      tinymce.IconManager.add('frankli-icons', {
        icons: FRANKLI_TINYMCE_ICONS,
      });
      tinymce.PluginManager.add('frankliRichLink', RICH_LINK_PLUGIN);
    }

    this.setDefaults();
    this.initRichLinkParsing();
    const saved = localStorage.getItem(this.localStorageKey);
    if (saved) {
      this.dynamicMaxCharacters = +saved;
    } else {
      this.dynamicMaxCharacters = this.maxCharacters;
    }

    this.editorConfig = {
      base_url: '/assets/tinymce',
      suffix: '.min',
      skin: 'oxide',
      icons: 'frankli-icons',
      plugins: this.plugins ? this.plugins.split(',').map((s) => s.trim()) : [],
      help_tabs: ['shortcuts', 'keyboardnav', 'about'],
      toolbar: this.toolbar as string,
      license_key: environment.tinymceLicenseKey,
      mobile: {
        menubar: this.menubarMobile,
        toolbar: this.toolbarMobile as string,
        plugins: this.pluginsMobile ? this.pluginsMobile.split(',').map((s) => s.trim()) : [],
      },
      selector: 'textarea',
      spellchecker_rpc_url: `${environment.baseUrl}/ephox-spelling`,
      spellchecker_language: 'en_US',
      help_accessibility: false,
      elementpath: false,
      branding: false,
      height: this.height,
      autoresize_min_height: 300,
      smart_paste: false, 
      powerpaste_word_import: 'clean', 

      setup: (editor) => {
        const comp = this;

        editor.on('blur', () => {
          // On blur, convert the current content and update the model.
          const converted = richLinkRemoveLinks(editor.getContent());
          comp.cachedValue = converted;
          comp._value = converted;
          comp.onChange(converted);
          comp.autoEvent.emit(true);
        });

        editor.on('init', () => {
          comp.editor = editor;
          comp.loading = false;
          if (comp._value) {
            editor.setContent(comp._value);
            setTimeout(() => {
              if (comp.doRichLinkParsing) {
                recursiveAddLinks(editor.getBody());
              }
            }, 10);
          }
          if (editor.plugins.wordcount && editor.plugins.wordcount.body) {
            comp.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
          }
          if (comp.disabled) {
            editor.mode.set('readonly');
          }
          comp.firstInitRun = true;
        });    

        // Prevent keystrokes once limit is reached but allow navigation or deletion.
         editor.on('keydown', (e) => {
            if (
              comp.enforceCharacterLimit &&
              editor.plugins.wordcount?.body &&
              editor.plugins.wordcount.body.getCharacterCount() >= comp.dynamicMaxCharacters
            ) {
            const allowedKeys = [
              'ArrowLeft',
              'ArrowRight',
              'ArrowUp',
              'ArrowDown',
              'Backspace',
              'Delete',
            ];
            const isCtrlOrCmd = e.ctrlKey || e.metaKey;

            if (!allowedKeys.includes(e.key) && !isCtrlOrCmd) {
              e.preventDefault();
              comp.warnWordcount = true;
              comp.cdRef.detectChanges();
            }
          }
        });

        editor.on('paste', (e) => {
          if (!comp.enforceCharacterLimit || !editor.plugins.wordcount?.body) {
            return;
          }
          
          // Prevent overlapping paste events
          if (comp.pasteProcessing) {
            return;
          }
          comp.pasteProcessing = true;
        
          try {
            const charCount = editor.plugins.wordcount.body.getCharacterCount();
            if (charCount >= comp.dynamicMaxCharacters) {
              e.preventDefault();
              comp.warnWordcount = true;
              comp.cdRef.detectChanges();
              return;
            }
          
            const pastedText = (e.clipboardData || (window as any).clipboardData).getData('text');
            const allowedChars = comp.dynamicMaxCharacters - charCount;
          
            // If the pasted text would exceed the limit, only allow up to the limit
            if (pastedText.length > allowedChars) {
              e.preventDefault();
              const partial = pastedText.substring(0, allowedChars);
              editor.insertContent(editor.dom.encode(partial));
              comp.warnWordcount = true;
              comp.cdRef.detectChanges();
            }
          } finally {
            setTimeout(() => {
              comp.pasteProcessing = false;
            }, 50);
          }
        });        

        editor.on('keyup change setcontent', () => {
          const contentText = editor.getContent({ format: 'text' });
          const newCount = contentText.length;
          comp.setWordCount(newCount);
        
          const current = editor.getContent();
          if (current !== comp.cachedValue) {
            comp.value = current;
          }
        });

        editor.on('setcontent', () => {
          setTimeout(() => {
            if (comp.doRichLinkParsing) {
              recursiveAddLinks(editor.getBody());
            }
          }, 10);
        });

        editor.on('ResizeEditor', () => {
          comp.updateDynamicMaxCharacters();
        });
        
      },
    };
  }

  ngOnDestroy() {
    this.removeEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  initRichLinkParsing(): void {
    if (this.plugins && this.plugins.includes('frankliRichLink')) {
      this.doRichLinkParsing = true;
    }
  }

  setDefaults() {
    // Desktop defaults
    if (!this.toolbar) {
      this.toolbar = TOOLBAR_DEFAULTS_DESKTOP;
    }
    if (!this.plugins) {
      this.plugins = PLUGIN_DEFAULTS_DESKTOP;
    }

    // Mobile defaults
    if (!this.toolbarMobile) {
      this.toolbarMobile = TOOLBAR_DEFAULTS_MOBILE;
    }
    if (!this.pluginsMobile) {
      this.pluginsMobile = PLUGIN_DEFAULTS_MOBILE;
    }
    if (this.menubarMobile === undefined || this.menubarMobile === null) {
      this.menubarMobile = MENUBAR_DEFAULTS_MOBILE;
    }
  }

  setWordCount(count: number) {
    this.wordcount = count;
    if (count < this.dynamicMaxCharacters) {
      this.warnWordcount = false;
    }
    this.wordcountChanged.emit(count);
    this.cdRef.detectChanges();
  }

  // NG_VALUE_ACCESSOR methods

  writeValue(value: any) {
    if (value === null || value === undefined) {
      value = '';
    }
    this._value = value;
    if (this.editor) {
      this.editor.setContent(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (this.editor) {
      if (isDisabled) {
        this.editor.mode.set('readonly');
      } else {
        this.editor.mode.set('design');
      }
    }
  }

  removeEditor() {
    if (this.editor) {
      this.editor.remove();
      this.editor = null;
    }
  }

  private updateDynamicMaxCharacters() {
    if (!this.editor) {
      return;
    }
    const containerHeight = this.editor.getContainer().offsetHeight || 300;
    const defaultHeight = 300;
  
    const ratio = containerHeight / defaultHeight;
    const scaled = Math.floor(this.maxCharacters * ratio);
  
    this.dynamicMaxCharacters = Math.min(scaled, 10000);

    localStorage.setItem(this.localStorageKey, this.dynamicMaxCharacters.toString());
  }
  
}