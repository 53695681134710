
export enum CompanyFeatures {
  ANALYTICS = 'ANALYTICS',
  CONNECT = 'CONNECT',
  CONNECT_COACHING = 'CONNECT_COACHING',
  CONNECT_SOCIALISE = 'CONNECT_SOCIALISE',

  FEEDBACK = 'FEEDBACK',
  FEEDBACK_UNPROMPTED = 'FEEDBACK_UNPROMPTED',
  FEEDBACK_PRAISE = 'FEEDBACK_PRAISE',
  FEEDBACK_IDEAS = 'FEEDBACK_IDEAS',
  FEEDBACK_PEER = 'FEEDBACK_PEER',
  FEEDBACK_REQUESTS = 'FEEDBACK_REQUESTS',

  GOALS = 'GOALS',

  HELP = 'HELP',

  PEOPLE_DIRECTORY = 'PEOPLE_DIRECTORY',
  SURVEYS = 'SURVEYS',

  ORG_CHART = 'ORG_CHART',
  ORG_CHART_GOALS_INFO = 'ORG_CHART_GOALS_INFO',

  ONE_TO_ONE = 'ONE_TO_ONE',

  GOAL_PRIORITY = 'GOAL_PRIORITY',
  GOAL_TEMPLATE = 'GOAL_TEMPLATE',
  GOAL_OFFICE_LOCATION = 'GOAL_OFFICE_LOCATION',

  EVALUATION_CYCLES = 'EVALUATION_CYCLES',
  PEER_NOMINATIONS = 'PEER_NOMINATIONS',

  COMPETENCIES = 'COMPETENCIES',
  GRADES = 'GRADES',
  TODOS = 'TODOS',

  SSO = 'SSO',
  SSO_MICROSOFT = 'SSO_MICROSOFT',
  SSO_GOOGLE = 'SSO_GOOGLE',

  ENPS = 'ENPS',
  FEEDBACK_BANK = 'FEEDBACK_BANK',
  FEEDBACK_EXTERNAL_REQUESTS = 'FEEDBACK_EXTERNAL_REQUESTS',
  PEER_FEEDBACK_MANAGER_ACCESS = 'PEER_FEEDBACK_MANAGER_ACCESS',

  AZURE_ACTIVE_DIRECTORY = 'AZURE_ACTIVE_DIRECTORY',
  PEOPLE_HR = 'PEOPLE_HR',
  LOGIN_EMAIL_PASSWORD = 'LOGIN_EMAIL_PASSWORD',
  CONNECT_MENTORING = 'CONNECT_MENTORING',
  SECONDARY_MANAGER = 'SECONDARY_MANAGER',
  VINCERE = 'VINCERE',
  TRINET_HRIS = 'TRINET_HRIS',
  SAGE_HR = 'SAGE_HR',
  ZENDESK = 'ZENDESK',
  SKILLS = 'SKILLS',
  QUALIFICATIONS = 'QUALIFICATIONS',

  CAREERS = 'CAREERS',
  CAREER_PATHWAYS = 'CAREER_PATHWAYS',
  ROLE_APPLICATIONS = 'ROLE_APPLICATIONS',
  QUESTION_TEMPLATES = 'QUESTION_TEMPLATES',
  CUSTOM_CAREER_PATHWAYS = 'CUSTOM_CAREER_PATHWAYS',

  COMPANY_DASHBOARD_CARD = 'COMPANY_DASHBOARD_CARD',
  EVALUAGENT = 'EVALUAGENT',
  MERGE = 'MERGE',
  ABSORBLMS = 'ABSORBLMS',

  ONE_TO_ONE_SENTIMENT_SCALE = 'ONE_TO_ONE_SENTIMENT_SCALE',

  SKILL_RATING = 'SKILL_RATING',
  ZENDESKQA = 'ZENDESKQA'
}
