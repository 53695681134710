export class CompetencyRatingOption implements ICompetencyRatingOption {
  id: number;
  scoreValue: number;
  name: string;
  description: string;

  constructor(serverSide: ICompetencyRatingOptionServerSide) {
    this.id = serverSide.id;
    this.scoreValue = serverSide.scoreValue;
    this.name = serverSide.name;
    this.description = serverSide.description;
  }

}

export interface ICompetencyRatingOption {
  id: number;
  scoreValue: number;
  name: string;
  description: string;
}

export interface ICompetencyRatingOptionServerSide {
  id: number;
  scoreValue: number;
  name: string;
  description: string;
}
