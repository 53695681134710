import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { Angulartics2Module } from 'angulartics2';

import { DatePickerOldComponent } from './utils/date-picker-old/date-picker-old.component';
import { FormInputDropdownComponent } from './utils/form-input-dropdown/form-input-dropdown.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ColumnToggleComponent } from './column-toggle/column-toggle.component';
import { ModalComponent } from './modal/modal.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { RichDisplayComponent } from './rich-display/rich-display.component';
import { UniversalFilterComponent } from './universal-filter/universal-filter.component';
import { GoalDropdownComponent } from './goal-dropdown/goal-dropdown.component';
import { GoalStatsComponent } from './goals-stats/goals-stats.component';
import { EditorResponseComponent } from './survey-components/editor-response/editor-response.component';

import { MultipleChoiceReportingComponent } from './multiple-choice-reporting/multiple-choice-reporting.component';
import { PersonDisplayComponent } from './components/person-display/person-display.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DateRangePickerComponent } from './utils/date-range-picker/date-range-picker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DatePickerComponent } from './utils/date-picker/date-picker.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TimePickerComponent } from './utils/time-picker/time-picker.component';
import { DateTimePickerComponent } from './utils/date-time-picker/date-time-picker.component';
import { FeedbackUnpromptedDisplayComponent } from './feedback/feedback-unprompted-display/feedback-unprompted-display.component';
import { FeedbackIdeaDisplayComponent } from './feedback/feedback-idea-display/feedback-idea-display.component';
import { FeedbackPraiseDisplayComponent } from './feedback/feedback-praise-display/feedback-praise-display.component';
import { FeedbackPeerDisplayComponent } from './feedback/feedback-peer-display/feedback-peer-display.component';
import { FeedbackRequestDisplayComponent } from './feedback/feedback-request-display/feedback-request-display.component';

import { SessionLogChartComponent } from './session-log/session-log-table/session-log-table.component';
import { SessionLogWeeklyBreakdownComponent } from './session-log/session-log-weekly-breakdown/session-log-weekly-breakdown.component';
import { FeedbackUploadDisplayComponent } from './feedback/feedback-upload-display/feedback-upload-display.component';
import { UploadedFileDisplayComponent } from './uploaded-file-display/uploaded-file-display.component';
import { CompetencyModalComponent } from '@app/domain/competency/component/competency-modal/competency-modal.component';
import { SessionLogUserStickinessOverviewDailyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-daily/session-log-user-stickiness-overview-daily.component';
import { SessionLogUserStickinessOverviewMonthlyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-monthly/session-log-user-stickiness-overview-monthly.component';
import { SessionLogUserStickinessOverviewWeeklyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-weekly/session-log-user-stickiness-overview-weekly.component';
import { RequestSandboxComponent } from './request-sandbox/request-sandbox.component';
import { UploadedFileLinkPreviewComponent } from './uploaded-file-link-preview/uploaded-file-link-preview.component';
import { FeedbackRequestResponseSingleComponent } from './feedback/feedback-request-response-single/feedback-request-response-single.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { DynamicFilterComponent } from '@app/shared/dynamic-filter/dynamic-filter.component';
import { UserPickerMinimalComponent } from '@app/shared/user-picker-minimal/user-picker-minimal.component';
import { EvaluationActivityModalComponent } from './components/evaluations/evaluation-activity-modal/evaluation-activity-modal.component';
import { RichLinkModalComponent } from './components/rich-link-modal/rich-link-modal.component';
import { LoadingStateComponent } from './components/states/loading-state/loading-state.component';
import { GoalDisplayComponent } from './components/displays/goal-display/goal-display.component';
import { PaginationModule } from './pagination/pagination.module';
import { LikertReportingChartComponent } from './components/reporting/likert-reporting-chart/likert-reporting-chart.component';
import { LikertReportingDistribuationChartComponent } from './components/reporting/likert-reporting-distribuation-chart/likert-reporting-distribuation-chart.component';
import { SurveyReportingCommentsComponent } from './components/reporting/survey-reporting-comments/survey-reporting-comments.component';
import { PillComponent } from './components/pill/pill.component';
import { CopyClipboardDirective } from './directives/copy-clipboard/copy-clipboard.directive';
import { DropdownDisplayComponent } from './components/displays/dropdown-display/dropdown-display.component';
import { PersonPictureListComponent } from './components/person-picture-list/person-picture-list.component';
import { SiteDisplayComponent } from './components/displays/site-display/site-display.component';
import { DepartmentDisplayComponent } from './components/displays/department-display/department-display.component';
import { GoalTemplateDisplayComponent } from './components/displays/goal-template-display/goal-template-display.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColumnToggleNewComponent } from '@app/shared/column-toggle-new/column-toggle-new.component';
import { SurveyScoreOverTimeChartComponent } from './components/reporting/survey-score-over-time-chart/survey-score-over-time-chart.component';
import { OneToTenReportingChartComponent } from './components/reporting/one-to-ten-reporting-chart/one-to-ten-reporting-chart.component';
import { OneToTenReportingDistributionChartComponent } from './components/reporting/one-to-ten-reporting-distribution-chart/one-to-ten-reporting-distribution-chart.component';
import { GoalSearchComponent } from '@app/shared/goal-search/goal-search.component';
import { GoalSearchItemComponent } from '@app/shared/goal-search/goal-search-item/goal-search-item.component';
import { UserDisplayComponent } from './components/displays/user-display/user-display.component';
import { ButtonGroupComponent } from './components/inputs/button-group/button-group.component';
import { ButtonWithDropdownComponent } from './components/inputs/button-with-dropdown/button-with-dropdown.component';
import { SliderInputComponent } from './components/inputs/slider-input/slider-input.component';
import { TableActionIconComponent } from './components/inputs/table-action-icon/table-action-icon.component';
import { TagPickerMultipleComponent } from './components/inputs/tag-picker-multiple/tag-picker-multiple.component';
import { UserPickerMultipleComponent } from './components/inputs/user-picker-multiple/user-picker-multiple.component';
import { UserPickerComponent } from './components/inputs/user-picker/user-picker.component';
import { VimeoPlayerComponent } from './components/vimeo-player/vimeo-player.component';
import { UniversalFilterCategoryItemComponent } from './universal-filter/universal-filter-category-item/universal-filter-category-item.component';
import { VincereSidebarComponent } from './components/vincere-sidebar/vincere-sidebar.component';
import { CheckComponent } from './components/check/check.component';
import { LanguageSelectorComponent } from '@app/i18n/language-selector.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DynamicFilterCategoryItemComponent } from '@app/shared/dynamic-filter/dynamic-filter-category-item/dynamic-filter-category-item.component';
import { GoalTypeDisplayComponent } from './components/goals/goal-type-display/goal-type-display.component';
import { GoalPriorityDisplayComponent } from './components/goals/goal-priority-display/goal-priority-display.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { KeyResultsListComponent } from './components/displays/key-results-list/key-results-list.component';
import { ErrorStateComponent } from './components/states/error-state/error-state.component';
import { ProgressBarRoundComponent } from './components/progress-bar-round/progress-bar-round.component';
import { ButtonComponent } from './components/inputs/button/button.component';
import { EmptyStateComponent } from './components/states/empty-state/empty-state.component';
import { TableDropdownToggleComponent } from './components/table-dropdown-toggle/table-dropdown-toggle.component';
import { GoalVisibilityDisplayComponent } from './components/goals/goal-visibility-display/goal-visibility-display.component';
import { SitePickerComponent } from './components/inputs/site-picker/site-picker.component';
import { DepartmentPickerComponent } from './components/inputs/department-picker/department-picker.component';
import { RolePickerComponent } from './components/inputs/role-picker/role-picker.component';
import { CompetencyDisplayComponent } from './components/displays/competency-display/competency-display.component';
import { StateComponent } from './components/states/state/state.component';
import { SetupStepsRemainingDisplayComponent } from './components/displays/setup-steps-remaining-display/setup-steps-remaining-display.component';
import { PeerReviewsTeamComponent } from 'app/shared/components/evaluations/peer-reviews-team/peer-reviews-team.component';
import { PeerReviewsItemTeamComponent } from 'app/shared/components/evaluations/peer-reviews-team/peer-reviews-item-team/peer-reviews-item-team.component';
import { PeerReviewProgressBarComponent } from 'app/shared/components/evaluations/peer-review-progress-bar/peer-review-progress-bar.component';
import { CycleStepDisplayComponent } from 'app/shared/components/evaluations/cycle-step-display/cycle-step-display.component';
import { PeerReviewsStatusArrowsComponent } from 'app/shared/components/evaluations/peer-reviews-status-arrows/peer-reviews-status-arrows.component';
import { BannerComponent } from './components/banner/banner.component';
import { HeaderComponent } from './components/header/header.component';
import { ZendeskSidebarComponent } from 'app/shared/components/zendesk-sidebar/zendesk-sidebar.component';
import { FrameWithHeadingComponent } from './components/frame-with-heading/frame-with-heading.component';
import { CompetencyPickerComponent } from '@app/domain/competency/component/competency-picker/competency-picker.component';
import { InterestPickerComponent } from '@app/domain/interest/component/interest-picker/interest-picker.component';
import { InterestDisplayComponent } from './components/displays/interest-display/interest-display.component';
import { YearPickerComponent } from './utils/year-picker/year-picker.component';
import { RoleDisplayComponent } from '@app/domain/role/component/role-display/role-display.component';
import { CheckboxInputComponent } from './components/inputs/checkbox-input/checkbox-input.component';
import { AccessLevelPickerComponent } from './components/inputs/access-level-picker/access-level-picker.component';
import { TextItemPickerComponent } from './components/inputs/text-item-picker/text-item-picker.component';
import { InformationSidebarComponent } from './components/information-sidebar/information-sidebar.component';
import { InformationSidebarGoalsComponent } from './components/information-sidebar/tabs/information-sidebar-goals/information-sidebar-goals.component';
import { InformationSidebarCompetenciesComponent } from './components/information-sidebar/tabs/information-sidebar-competencies/information-sidebar-competencies.component';
import { InformationSidebarReviewsComponent } from './components/information-sidebar/tabs/information-sidebar-reviews/information-sidebar-reviews.component';
import { InformationSidebarOneToOnesComponent } from './components/information-sidebar/tabs/information-sidebar-one-to-ones/information-sidebar-one-to-ones.component';
import { OneToOneScheduleDetailedDisplayComponent } from './components/displays/one-to-one-schedule-detailed-display/one-to-one-schedule-detailed-display.component';
import { ReviewCycleDisplayComponent } from './components/displays/review-cycle-display/review-cycle-display.component';
import { OneToOneMeetingDisplayComponent } from './components/displays/one-to-one-meeting-display/one-to-one-meeting-display.component';
import { OneToOneTalkingPointDisplayComponent } from './components/displays/one-to-one-talking-point-display/one-to-one-talking-point-display.component';
import { InformationSidebarQualificationsComponent } from './components/information-sidebar/tabs/information-sidebar-qualifications/information-sidebar-qualifications.component';
import { InformationSidebarSkillsComponent } from './components/information-sidebar/tabs/information-sidebar-skills/information-sidebar-skills.component';
import { InformationSidebarTasksComponent } from './components/information-sidebar/tabs/information-sidebar-tasks/information-sidebar-tasks.component';
import { InformationSidebarZendeskComponent } from './components/information-sidebar/tabs/information-sidebar-zendesk/information-sidebar-zendesk.component';
import { InformationSidebarZendeskqaComponent } from './components/information-sidebar/tabs/information-sidebar-zendeskqa/information-sidebar-zendeskqa.component';
import { InformationSidebarMergeTicketingComponent } from './components/information-sidebar/tabs/information-sidebar-merge-ticketing/information-sidebar-merge-ticketing.component';
import { InformationSidebarMergeCrmComponent } from './components/information-sidebar/tabs/information-sidebar-merge-crm/information-sidebar-merge-crm.component';
import { InformationSidebarVincereComponent } from './components/information-sidebar/tabs/information-sidebar-vincere/information-sidebar-vincere.component';
import { QualificationDisplayComponent } from '@app/domain/qualification/component/qualification-display/qualification-display.component';
import { AuthLayoutComponent } from '@app/auth/auth-components/auth-layout/auth-layout.component';
import { TagDisplayComponent } from './components/displays/tag-display/tag-display.component';
import { TaskPickerComponent } from './components/inputs/task-picker/task-picker.component';
import { SortingDropdownComponent } from './components/inputs/sorting-dropdown/sorting-dropdown.component';
import { RoleApplicationPickerComponent } from './components/inputs/role-application-picker/role-application-picker.component';
import { TagPickerComponent } from './components/inputs/tag-picker/tag-picker.component';
import { EvaluagentSidebarComponent } from './components/evaluagent-sidebar/evaluagent-sidebar.component';
import { EvaluagentEvaluationDisplayComponent } from './components/displays/evaluagent-evaluation-display/evaluagent-evaluation-display.component';
import { EvaluagentScorecardAverageDisplayComponent } from './components/displays/evaluagent-scorecard-average-display/evaluagent-scorecard-average-display.component';
import { TrendArrowComponent } from './components/trend-arrow/trend-arrow.component';
import { HamburgerMenuComponent } from './components/hamburger-menu/hamburger-menu.component';
import { TaskDisplayComponent } from '@app/domain/task/component/task-display/task-display.component';
import { AbsorblmsSidebarComponent } from './components/absorblms-sidebar/absorblms-sidebar.component';
import { AbsorblmsCertificateDisplayComponent } from './components/displays/absorblms-certificate-display/absorblms-certificate-display.component';
import { AbsorblmsEnrollmentDisplayComponent } from './components/displays/absorblms-enrollment-display/absorblms-enrollment-display.component';
import { InformationSidebarAbsorblmsComponent } from './components/information-sidebar/tabs/information-sidebar-absorblms/information-sidebar-absorblms.component';
import { GoalPickerComponent } from './components/inputs/goal-picker/goal-picker.component';
import { OneToOneScheduleDisplayComponent } from '@app/domain/one_to_one/component/one-to-one-schedule-display/one-to-one-schedule-display.component';
import { InformationSidebarScheduleCalendarComponent } from './components/information-sidebar/tabs/information-sidebar-schedule-calendar/information-sidebar-schedule-calendar.component';
import { QualificationSidebarDisplayComponent } from '@app/domain/qualification/component/qualification-sidebar-display/qualification-sidebar-display.component';
import { InformationSidebarEvaluagentComponent } from './components/information-sidebar/tabs/information-sidebar-evaluagent/information-sidebar-evaluagent.component';
import { InformationSidebarFeedbackUnpromptedComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-unprompted/information-sidebar-feedback-unprompted.component';
import { InformationSidebarFeedbackPraiseComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-praise/information-sidebar-feedback-praise.component';
import { InformationSidebarFeedbackIdeasComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-ideas/information-sidebar-feedback-ideas.component';
import { InformationSidebarFeedbackPeerComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-peer/information-sidebar-feedback-peer.component';
import { InformationSidebarFeedbackRequestsComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-requests/information-sidebar-feedback-requests.component';
import { InformationSidebarFeedbackExternalComponent } from './components/information-sidebar/tabs/information-sidebar-feedback-external/information-sidebar-feedback-external.component';
import { InformationSidebarActionPointsComponent } from './components/information-sidebar/tabs/information-sidebar-action-points/information-sidebar-action-points.component';
import { TaskDetailsComponent } from '@app/domain/task/component/task-details/task-details.component';
import { TaskActivityDisplayComponent } from '@app/domain/task/component/task-activity-display/task-activity-display.component';
import { TaskActivityComponent } from '@app/domain/task/component/task-activity/task-activity.component';
import { TaskCreateComponent } from '@app/domain/task/component/create-task/task-create.component';
import { TaskCommentDisplayComponent } from '@app/domain/task/component/task-comment-display/task-comment-display.component';
import { TaskStatusPillComponent } from '@app/domain/task/component/task-status-pill/task-status-pill.component';
import { OneToOneNoteDisplayComponent } from './components/displays/one-to-one-note-display/one-to-one-note-display.component';
import { ProfileAbsorblmsCertificatesComponent } from '@app/domain/one_to_one/component/profile-absorblms-certificates/profile-absorblms-certificates.component';
import { ProfileOneToOnesComponent } from '@app/domain/one_to_one/component/profile-one-to-ones/profile-one-to-ones.component';
import { InformationSidebarSentimentScaleTimelineComponent } from './components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline.component';
import { InformationSidebarSentimentScaleTimelineMeetingItemComponent } from './components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline-meeting-item/information-sidebar-sentiment-scale-timeline-meeting-item.component';
import { InformationSidebarSentimentScaleTimelineAnswerItemComponent } from './components/information-sidebar/tabs/information-sidebar-sentiment-scale-timeline/information-sidebar-sentiment-scale-timeline-answer-item/information-sidebar-sentiment-scale-timeline-answer-item.component';
import { SentimentScaleDisplayComponent } from '@app/domain/sentiment_scale/component/sentiment-scale-display/sentiment-scale-display.component';
import { SentimentScaleOptionDisplayComponent } from '@app/domain/sentiment_scale/component/sentiment-scale-option-display/sentiment-scale-option-display.component';
import { SentimentScaleResponseComponent } from '@app/domain/sentiment_scale/component/sentiment-scale-response/sentiment-scale-response.component';
import { SkillRatingDisplayComponent } from '../domain/skill_rating/component/skill-rating-display/skill-rating-display.component';
import { StarScaleComponent } from './survey-components/star-scale/star-scale.component';
import { InformationSidebarReviewsSelfReflectionComponent } from './components/information-sidebar/tabs/information-sidebar-reviews/information-sidebar-reviews-self-reflection/information-sidebar-reviews-self-reflection.component';
import { InformationSidebarReviewsManagerReviewComponent } from './components/information-sidebar/tabs/information-sidebar-reviews/information-sidebar-reviews-manager-review/information-sidebar-reviews-manager-review.component';
import { InformationSidebarReviewsPeerReviewsComponent } from './components/information-sidebar/tabs/information-sidebar-reviews/information-sidebar-reviews-peer-reviews/information-sidebar-reviews-peer-reviews.component';
import { InformationSidebarReviewsUpwardReviewsComponent } from './components/information-sidebar/tabs/information-sidebar-reviews/information-sidebar-reviews-upward-reviews/information-sidebar-reviews-upward-reviews.component';
import { DynamicFormQuestionComponent } from './survey-components/dynamic-form/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './survey-components/dynamic-form/dynamic-form.component';
import { DynamicScaleOptionComponent } from './survey-components/dynamic-scale-option/dynamic-scale-option.component';
import { DynamicScaleComponent } from './survey-components/dynamic-scale/dynamic-scale.component';
import { EmotionScaleComponent } from './survey-components/emotion-scale/emotion-scale.component';
import { EnpsScaleComponent } from './survey-components/enps-scale/enps-scale.component';
import { LikertScaleComponent } from './survey-components/likert-scale/likert-scale.component';
import { MultiChoiceComponent } from './survey-components/multi-choice/multi-choice.component';
import { OneToFiveScaleEditComponent } from './survey-components/one-to-five-scale-edit/one-to-five-scale-edit.component';
import { OneToFiveScaleComponent } from './survey-components/one-to-five-scale/one-to-five-scale.component';
import { OneToFourScaleEditComponent } from './survey-components/one-to-four-scale-edit/one-to-four-scale-edit.component';
import { OneToFourScaleComponent } from './survey-components/one-to-four-scale/one-to-four-scale.component';
import { OneToThreeScaleEditComponent } from './survey-components/one-to-three-scale-edit/one-to-three-scale-edit.component';
import { OneToThreeScaleComponent } from './survey-components/one-to-three-scale/one-to-three-scale.component';
import { ScaleComponent } from './survey-components/scale/scale.component';
import { SingleChoiceComponent } from './survey-components/single-choice/single-choice.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SidebarQuestionItemComponent } from './components/displays/sidebar-question-item/sidebar-question-item.component';
import { EvaluationFeedbackRequestDisplayComponent } from '@app/domain/review_cycle/component/evaluation-feedback-request-display/evaluation-feedback-request-display.component';
import { SelfReflectionDisplayComponent } from '@app/domain/review_cycle/component/self-reflection-display/self-reflection-display.component';
import { ReviewStepSummaryDisplayComponent } from '@app/domain/review_cycle/component/review-step-summary-display/review-step-summary-display.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// TODO: Split this into sub modules
// TODO: Move all the inputs to ./inputs.module.ts
const components = [
  DatePickerOldComponent, // TODO: Remove this - new datepicker needs dropdown and timepicker configured first
  RichDisplayComponent,
  FormInputDropdownComponent,
  BreadcrumbsComponent,
  FormInputDropdownComponent,
  ColumnToggleComponent,
  ColumnToggleNewComponent,
  ModalComponent,
  ProfilePhotoComponent,
  NotFoundComponent,
  UniversalFilterComponent,
  UniversalFilterCategoryItemComponent,
  GoalDropdownComponent,
  GoalStatsComponent,
  EditorResponseComponent,
  GoalStatsComponent,
  MultipleChoiceReportingComponent,
  PersonDisplayComponent,
  FileUploadComponent,
  DateRangePickerComponent,
  DatePickerComponent,
  TimePickerComponent,
  DateTimePickerComponent,
  SessionLogChartComponent,
  SessionLogWeeklyBreakdownComponent,
  TagPickerComponent,

  FeedbackUnpromptedDisplayComponent,
  FeedbackIdeaDisplayComponent,
  FeedbackPraiseDisplayComponent,
  FeedbackPeerDisplayComponent,
  FeedbackRequestDisplayComponent,
  FeedbackUploadDisplayComponent,

  UploadedFileDisplayComponent,
  UploadedFileLinkPreviewComponent,

  SessionLogWeeklyBreakdownComponent,
  CompetencyModalComponent,
  SessionLogUserStickinessOverviewDailyComponent,
  SessionLogUserStickinessOverviewWeeklyComponent,
  SessionLogUserStickinessOverviewMonthlyComponent,
  RequestSandboxComponent,
  FeedbackRequestResponseSingleComponent,

  NavTabsComponent,
  DynamicFilterComponent,
  DynamicFilterCategoryItemComponent,
  UserPickerMinimalComponent,

  EvaluationActivityModalComponent,
  LikertReportingChartComponent,
  LikertReportingDistribuationChartComponent,
  SurveyReportingCommentsComponent,

  CopyClipboardDirective,
  RichLinkModalComponent,

  // STATES
  LoadingStateComponent,
  ErrorStateComponent,
  EmptyStateComponent,

  // Displays
  PillComponent,
  PersonPictureListComponent,
  DepartmentDisplayComponent,
  DropdownDisplayComponent,
  GoalDisplayComponent,
  SiteDisplayComponent,
  UserDisplayComponent,
  CompetencyDisplayComponent,
  GoalTemplateDisplayComponent,
  PillComponent,
  CheckComponent,
  SurveyScoreOverTimeChartComponent,
  VimeoPlayerComponent,
  GoalPriorityDisplayComponent,
  GoalTypeDisplayComponent,
  GoalVisibilityDisplayComponent,
  ProgressBarComponent,
  ProgressBarRoundComponent,
  KeyResultsListComponent,
  TableDropdownToggleComponent,
  TaskDisplayComponent,
  InterestDisplayComponent,
  RoleDisplayComponent,
  OneToOneScheduleDisplayComponent,
  OneToOneScheduleDetailedDisplayComponent,
  OneToOneMeetingDisplayComponent,
  OneToOneTalkingPointDisplayComponent,
  OneToOneNoteDisplayComponent,
  ReviewCycleDisplayComponent,
  QualificationDisplayComponent,
  QualificationSidebarDisplayComponent,
  TagDisplayComponent,
  SkillRatingDisplayComponent,

  // Reporting
  OneToTenReportingChartComponent,
  OneToTenReportingDistributionChartComponent,

  VimeoPlayerComponent,
  ZendeskSidebarComponent,
  VincereSidebarComponent,
  EvaluagentSidebarComponent,
  EvaluagentEvaluationDisplayComponent,
  EvaluagentScorecardAverageDisplayComponent,
  AbsorblmsSidebarComponent,
  AbsorblmsCertificateDisplayComponent,
  AbsorblmsEnrollmentDisplayComponent,

  // Searching
  GoalSearchComponent,
  GoalSearchItemComponent,

  // Form inputs
  SliderInputComponent,
  ButtonWithDropdownComponent,
  ButtonGroupComponent,
  SitePickerComponent,
  DepartmentPickerComponent,
  RolePickerComponent,
  UserPickerComponent,
  UserPickerMultipleComponent,
  TagPickerMultipleComponent,
  TableActionIconComponent,
  LanguageSelectorComponent,
  ButtonComponent,
  CompetencyPickerComponent,
  InterestPickerComponent,
  AccessLevelPickerComponent,
  TextItemPickerComponent,
  TaskPickerComponent,
  YearPickerComponent,
  RoleApplicationPickerComponent,
  GoalPickerComponent,
  StarScaleComponent,

  StateComponent,

  PeerReviewsTeamComponent,
  PeerReviewsItemTeamComponent,
  PeerReviewProgressBarComponent,
  CycleStepDisplayComponent,
  PeerReviewsStatusArrowsComponent,
  SetupStepsRemainingDisplayComponent,
  BannerComponent,
  HeaderComponent,
  FrameWithHeadingComponent,
  CheckboxInputComponent,
  EvaluationFeedbackRequestDisplayComponent,
  SelfReflectionDisplayComponent,
  ReviewStepSummaryDisplayComponent,

  InformationSidebarComponent,
  InformationSidebarScheduleCalendarComponent,
  InformationSidebarGoalsComponent,
  InformationSidebarCompetenciesComponent,
  InformationSidebarOneToOnesComponent,
  InformationSidebarReviewsComponent,
  InformationSidebarReviewsSelfReflectionComponent,
  InformationSidebarReviewsManagerReviewComponent,
  InformationSidebarReviewsPeerReviewsComponent,
  InformationSidebarReviewsUpwardReviewsComponent,
  InformationSidebarQualificationsComponent,
  InformationSidebarSkillsComponent,
  InformationSidebarTasksComponent,
  InformationSidebarZendeskComponent,
  InformationSidebarZendeskqaComponent,
  InformationSidebarMergeTicketingComponent,
  InformationSidebarMergeCrmComponent,
  InformationSidebarVincereComponent,
  InformationSidebarAbsorblmsComponent,
  InformationSidebarEvaluagentComponent,
  InformationSidebarFeedbackUnpromptedComponent,
  InformationSidebarFeedbackPraiseComponent,
  InformationSidebarFeedbackIdeasComponent,
  InformationSidebarFeedbackPeerComponent,
  InformationSidebarFeedbackRequestsComponent,
  InformationSidebarFeedbackExternalComponent,
  InformationSidebarActionPointsComponent,
  InformationSidebarSentimentScaleTimelineComponent,
  InformationSidebarSentimentScaleTimelineMeetingItemComponent,
  InformationSidebarSentimentScaleTimelineAnswerItemComponent,

  AuthLayoutComponent,

  SortingDropdownComponent,
  TrendArrowComponent,
  HamburgerMenuComponent,

  ProfileOneToOnesComponent,
  ProfileAbsorblmsCertificatesComponent,

  // Tasks
  TaskDetailsComponent,
  TaskActivityComponent,
  TaskActivityDisplayComponent,
  TaskCreateComponent,
  TaskCommentDisplayComponent,
  TaskStatusPillComponent,

  // Sentiment scales
  SentimentScaleDisplayComponent,
  SentimentScaleOptionDisplayComponent,
  SentimentScaleResponseComponent,

  EmotionScaleComponent,
  LikertScaleComponent,
  MultiChoiceComponent,
  SingleChoiceComponent,
  OneToFourScaleComponent,
  OneToFourScaleEditComponent,
  OneToThreeScaleComponent,
  OneToThreeScaleEditComponent,
  OneToFiveScaleComponent,
  OneToFiveScaleEditComponent,
  DynamicFormComponent,
  DynamicFormQuestionComponent,
  EnpsScaleComponent,
  ScaleComponent,
  DynamicScaleComponent,
  DynamicScaleOptionComponent,
  SidebarQuestionItemComponent,
];

const importAndExport = [
  FormsModule,
  ReactiveFormsModule,
  SweetAlert2Module,
  Angulartics2Module,
  PipesModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularMyDatePickerModule,
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    DragDropModule,
    //Tiny mce
    EditorModule,
    ...importAndExport
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: '/assets/tinymce/tinymce.min.js' },
  ],
  declarations: components,
  exports: [
    ...components,
    ...importAndExport
  ]
})
export class SharedModule { }
