import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MergeDevTicket } from '@app/models/integrations/mergedev/ticketing/merge-dev-ticket.model';
import { MergeDevTicketingUserStats } from '@app/models/integrations/mergedev/ticketing/merge-dev-ticketing-user-stats.model';
import { IState } from '@app/models/state/state.model';
import { User } from '@app/models/user/user.model';
import { MergeDevAPIService } from '@app/shared/api/mergedev.api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { DateRangePickerPresetRanges } from '@app/shared/utils/date-range-picker/date-range-picker.component';
import moment from 'moment';
import { of } from 'rxjs';

enum TicketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ALL = 'ALL',
}

// Add other properties to this as needed, we currently only use the image
type MergeDevIntegration = {
  image: string;
};

@Component({
  selector: 'app-information-sidebar-merge-ticketing',
  templateUrl: './information-sidebar-merge-ticketing.component.html',
  styleUrls: ['./information-sidebar-merge-ticketing.component.scss'],
})
export class InformationSidebarMergeTicketingComponent implements OnInit {
  public readonly eTicketStatus = TicketStatus;

  @Input() userIds: number[];
  @ViewChild('modal') modal?: ModalComponent;

  openDatepickerEvent: EventEmitter<boolean>;

  state: IState;
  modalState: IState;
  stats: MergeDevTicketingUserStats;
  hasData: boolean;
  subjectUserId: number;
  subjectUser: User;
  tickets: MergeDevTicket[];
  dateRangePickerPresets: DateRangePickerPresetRanges;
  filters: FormGroup;
  // The properties for the integration which is linked. Contains name, logo etc.
  integration: MergeDevIntegration;

  get dateRangeStart(): Date {
    return this.filters.controls.dateRange.value.start;
  }

  get dateRangeEnd(): Date {
    return this.filters.controls.dateRange.value.end;
  }

  get controlDateRange(): FormControl {
    return this.filters.controls.dateRange as FormControl;
  }

  constructor(
    private mergeDevApiService: MergeDevAPIService,
    private userService: UserAPIService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
    };
    this.modalState = {
      loading: true,
      error: false,
      errorMessage: '',
    };
    this.subjectUser = {
      id: 0,
      version: 0,
      passwordReset: false,
      userState: null,
      firstName: 'User',
      lastName: '',
      email: '',
    } as User;
    this.stats = {
      userId: 0,
      companyId: 0,
      hasStats: false,
      openTickets: null,
      closedTickets: null,
      totalTickets: null,
    };
    this.tickets = undefined;
    this.hasData = undefined;
    this.integration = undefined;
    this.dateRangePickerPresets = undefined;
    this.filters = this.initFilters();
    this.openDatepickerEvent = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.verifySubjectUser();
    this.getFullSubjectUser();
    this.getLinkedIntegration();
    this.populateDateRangePickerPresetData();
    this.filters.valueChanges.subscribe(() => {
      this.getTicketingStatsData(
        this.filters.value.dateRange.start,
        this.filters.value.dateRange.end
      );
    });
    this.checkUserHasAnyData();
    this.getTicketingStatsData(
      this.filters.value.dateRange.start,
      this.filters.value.dateRange.end
    );
  }

  checkUserHasAnyData(): void {
    if (this.hasData) {
      return;
    }

    this.mergeDevApiService
      .getTicketStatsByUser(this.subjectUserId, 730)
      .subscribe((data) => {
        this.hasData = data.totalTickets > 0;
      });
  }

  initFilters(): FormGroup {
    const formGroup = new FormGroup({
      dateRange: new FormControl(
        {
          start: moment().subtract(1, 'month').startOf('month').toDate(),
          end: moment().subtract(1, 'month').endOf('month').toDate(),
          label: 'Default Range',
        },
        []
      ),
    });

    return formGroup;
  }

  verifySubjectUser(): void {
    if (!this.userIds || this.userIds === undefined) {
      this.error();
      return;
    }
    if (this.userIds.length < 1) {
      this.error();
      return;
    }
    this.subjectUserId = this.userIds[0] || 0;
    if (this.subjectUserId === 0) {
      this.error();
      return;
    }
  }

  error(): void {
    this.state.loading = false;
    this.state.error = true;
  }

  // Get the full user object for the subject user
  // This is only used to show the user's name
  getFullSubjectUser(): void {
    this.userService.getById(this.subjectUserId).subscribe((response) => {
      if (!response) {
        return;
      }
      this.subjectUser = response;
    }),
      (error) => {
        console.log(error);
      };
  }

  openTicketModal(status: TicketStatus) {
    this.getTicketingData(status);
    // Don't try open a modal if it doesn't show any stats
    if (!this.tickets) {
      return;
    }

    if (this.tickets.length < 1) {
      return;
    }
    this.modal.show();
  }

  closeModal(): void {
    this.modal.hide();
  }

  // Get the name and logo of the linked ticketing integration
  getLinkedIntegration(): Promise<void> {
    // Get linked integrations from the api service
    this.mergeDevApiService.getLinkedAccounts().subscribe((response) => {
      if (!response || response.length === 0) {
        return;
      }

      // Filter the response by integrations of category 'ticketing'
      // There should only be ever be one linked ticketing integration so we take the first one '[0]'
      const filteredItems = response.filter(
        (item) => item.category === 'ticketing'
      );
      this.integration =
        filteredItems.length > 0 ? filteredItems[0].integration : undefined;

      // If there is no integration, show the error state
      if (this.integration === undefined) {
        // this.error();
        this.integration.image = 'assets/img/integrations/merge.svg';
        return;
      }
    }),
      (error) => {
        console.log(error);
      };

    return;
  }

  getTicketingData(status: TicketStatus): void {
    this.modalState.loading = true;
    this.mergeDevApiService.getTicketsByUser(this.subjectUserId, 30).subscribe(
      (response) => {
        if (status !== TicketStatus.ALL) {
          response = response.filter((ticket) => ticket.status === status);
        }
        this.tickets = response;
        this.modalState.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // Get the ticketing data for a user
  getTicketingStatsData(periodStart: Date, periodEnd: Date): void {
    this.mergeDevApiService
      .getTicketStatsByUserBetweenDates(
        this.subjectUserId,
        periodStart.toISOString(),
        periodEnd.toISOString()
      )
      .subscribe((response) => {
        if (response) {
          this.stats = response;
          this.state.loading = false;
        }
      }),
      (error) => {
        this.state.loading = false;
        this.state.error = true;
        this.state.errorMessage = 'Failed to load ticketing data';
        console.log(error);
      };

    if (this.stats === undefined) {
      this.state.loading = false;
      this.state.error = true;
    }
  }

  openDateRangePicker(): void {
    this.openDatepickerEvent.emit(true);
  }

  populateDateRangePickerPresetData(): Promise<DateRangePickerPresetRanges> {
    const dateRanges: DateRangePickerPresetRanges = {
      'This Month': [
        moment().startOf('month').toDate(),
        moment().toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
      'This Quarter': [
        moment().startOf('quarter').toDate(),
        moment().endOf('quarter').toDate(),
      ],
      'Last Quarter': [
        moment().subtract(1, 'quarter').startOf('quarter').toDate(),
        moment().subtract(1, 'quarter').endOf('quarter').toDate(),
      ],
      'This Year': [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
    };
    this.dateRangePickerPresets = dateRanges;

    return of(dateRanges).toPromise();
  }
}
