import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MergeDevCRMStats } from '@app/models/integrations/mergedev/crm/merge-dev-crm-stats.model';
import { IState } from '@app/models/state/state.model';
import { User } from '@app/models/user/user.model';
import { MergeDevAPIService } from '@app/shared/api/mergedev.api.service';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { DateRangePickerPresetRanges } from '@app/shared/utils/date-range-picker/date-range-picker.component';
import moment from 'moment';
import { of } from 'rxjs';

// Add other properties to this as needed, we currently only use the image
type MergeDevIntegration = {
  image: string;
};

type MergeDevCRMSidebarExpand = {
  engagements: boolean;
  tasks: boolean;
  leads: boolean;
  opportunities: boolean;
};

@Component({
  selector: 'app-information-sidebar-merge-crm',
  templateUrl: './information-sidebar-merge-crm.component.html',
  styleUrls: ['./information-sidebar-merge-crm.component.scss'],
})
export class InformationSidebarMergeCrmComponent implements OnInit {
  @Input() userIds: number[];

  openDatepickerEvent: EventEmitter<boolean>;

  controlTab: FormControl;
  crmStats: MergeDevCRMStats;
  hasData: boolean;
  integration: MergeDevIntegration;
  dateRangePickerPresets: DateRangePickerPresetRanges;
  filters: FormGroup;
  state: IState;
  subjectUser: User;
  subjectUserId: number;
  expand: MergeDevCRMSidebarExpand;

  get dateRangeStart(): Date {
    return this.filters.controls.dateRange.value.start;
  }

  get dateRangeEnd(): Date {
    return this.filters.controls.dateRange.value.end;
  }

  get controlDateRange(): FormControl {
    return this.filters.controls.dateRange as FormControl;
  }

  constructor(
    private mergeDevApiService: MergeDevAPIService,
    private userService: UserAPIService
  ) {
    this.state = {
      loading: true,
      error: false,
      errorMessage: '',
    };
    this.expand = {
      engagements: false,
      tasks: false,
      leads: false,
      opportunities: false,
    };
    this.crmStats = undefined;
    this.hasData = undefined;
    this.integration = undefined;
    this.dateRangePickerPresets = undefined;
    this.filters = this.initFilters();
    this.openDatepickerEvent = new EventEmitter<boolean>();
    this.subjectUser = {
      id: 0,
      version: 0,
      passwordReset: false,
      userState: null,
      firstName: 'User',
      lastName: '',
      email: '',
    } as User;
  }

  ngOnInit(): void {
    // Get the subject user from input and make sure it's not empty
    this.verifySubjectUser();
    this.getFullSubjectUser();
    this.populateDateRangePickerPresetData();
    // Set up the filter control to run getCRMData every time the value changes
    this.filters.valueChanges.subscribe(() => {
      this.getCRMData(
        this.filters.value.dateRange.start,
        this.filters.value.dateRange.end
      );
    });
    // Check if the user has ANY CRM data at all
    this.checkUserHasAnyData();
    // Run getCRMData onInit to occupy the stats for the first time
    this.getCRMData(
      this.filters.value.dateRange.start,
      this.filters.value.dateRange.end
    );
    this.getLinkedIntegration();
    this.state.loading = false;
  }

  initFilters(): FormGroup {
    const formGroup = new FormGroup({
      dateRange: new FormControl(
        {
          start: moment().subtract(1, 'month').startOf('month').toDate(),
          end: moment().subtract(1, 'month').endOf('month').toDate(),
          label: 'Default Range',
        },
        []
      ),
    });

    return formGroup;
  }

  // Get the name and logo of the linked ticketing integration
  getLinkedIntegration(): Promise<void> {
    // Get linked integrations from the api service
    this.mergeDevApiService.getLinkedAccounts().subscribe((response) => {
      if (!response || response.length === 0) {
        return;
      }

      // Filter the response by integrations of category 'ticketing'
      // There should only be ever be one linked ticketing integration so we take the first one '[0]'
      const filteredItems = response.filter((item) => item.category === 'crm');
      this.integration =
        filteredItems.length > 0 ? filteredItems[0].integration : undefined;

      // If there is no integration, show the error state
      if (this.integration === undefined) {
        // this.error();
        this.integration.image = 'assets/img/integrations/merge.svg';
        return;
      }
    }),
      (error) => {
        console.log(error);
      };

    return;
  }

  verifySubjectUser(): void {
    if (!this.userIds || this.userIds === undefined) {
      this.error();
      return;
    }
    if (this.userIds.length < 1) {
      this.error();
      return;
    }
    this.subjectUserId = this.userIds[0] || 0;
    if (this.subjectUserId === 0) {
      this.error();
      return;
    }
  }

  getCRMData(periodStart: Date, periodEnd: Date): void {
    this.state.loading = true;
    this.mergeDevApiService
      .getCRMStatsByUserBetweenDates(
        this.subjectUserId,
        periodStart.toISOString(),
        periodEnd.toISOString()
      )
      .subscribe((data) => {
        const hasStats = (stats) => stats.totalCount > 0;

        data.hasStats = [
          data.engagementStats,
          data.taskStats,
          data.leadsStats,
        ].some(hasStats);

        this.crmStats = data;

        // Runs only for the first data sync
        if (this.hasData === undefined) {
          this.hasData = data.hasStats;
        }

        this.state.loading = false;
      });
  }

  // Checks if a user has any tickets at all
  checkUserHasAnyData(): void {
    if (this.hasData) {
      return;
    }

    this.mergeDevApiService
      .getCRMStatsByUser(this.subjectUserId)
      .subscribe((data) => {
        const hasStats = (stats) => stats.totalCount > 0;
        this.hasData = [
          data.engagementStats,
          data.taskStats,
          data.leadsStats,
        ].some(hasStats);
      });
  }

  getFullSubjectUser(): void {
    this.userService.getById(this.subjectUserId).subscribe((response) => {
      if (!response) {
        return;
      }
      this.subjectUser = response;
    }),
      (error) => {
        console.log(error);
      };
  }

  error(): void {
    this.state.loading = false;
    this.state.error = true;
  }

  populateDateRangePickerPresetData(): Promise<DateRangePickerPresetRanges> {
    const dateRanges: DateRangePickerPresetRanges = {
      'This Month': [
        moment().startOf('month').toDate(),
        moment().toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
      'This Quarter': [
        moment().startOf('quarter').toDate(),
        moment().endOf('quarter').toDate(),
      ],
      'Last Quarter': [
        moment().subtract(1, 'quarter').startOf('quarter').toDate(),
        moment().subtract(1, 'quarter').endOf('quarter').toDate(),
      ],
      'This Year': [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ],
    };

    this.dateRangePickerPresets = dateRanges;

    return of(dateRanges).toPromise();
  }
}
