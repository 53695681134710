import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MergeDevLinkTokenResponse } from '@models/integrations/mergedev/merge-dev-link-token.model';
import { Observable } from 'rxjs';
import { MergeDevLinkedAccount } from '@app/models/integrations/mergedev/merge-dev-linked-account.model';
import { MergeDevAccountToken } from '@app/models/integrations/mergedev/merge-dev-account-token.model';
import { MergeDevTicketingUserStats } from '@app/models/integrations/mergedev/ticketing/merge-dev-ticketing-user-stats.model';
import { MergeDevCRMStats } from '@app/models/integrations/mergedev/crm/merge-dev-crm-stats.model';
import { MergeDevTicket } from '@app/models/integrations/mergedev/ticketing/merge-dev-ticket.model';

@Injectable()
export class MergeDevAPIService {
  private readonly BASE_URL = 'api/merge-dev';

  constructor(private http: HttpClient) {}

  getLinkToken(): Observable<MergeDevLinkTokenResponse> {
    const url = `${this.BASE_URL}/link-key`;
    return this.http.get<MergeDevLinkTokenResponse>(url);
  }

  createAccountTokenFromPublicToken(
    publicToken: string
  ): Observable<MergeDevAccountToken> {
    const url = `${this.BASE_URL}/account-token`;
    return this.http.post<MergeDevAccountToken>(url, publicToken);
  }

  getAccountTokens(): Observable<MergeDevAccountToken[]> {
    const url = 'api/merge-dev/account-tokens';
    return this.http.get<MergeDevAccountToken[]>(url);
  }

  getLinkedAccounts(): Observable<MergeDevLinkedAccount[]> {
    const url = `${this.BASE_URL}/linked-accounts`;
    return this.http.get<MergeDevLinkedAccount[]>(url);
  }

  hasLinkedIntegrationForCategory(category: string): Observable<boolean> {
    const url = `${this.BASE_URL}/linked-accounts/${category}`;
    return this.http.get<boolean>(url);
  }

  getTicketStatsByUser(
    userId: number,
    days: number
  ): Observable<MergeDevTicketingUserStats> {
    const url = `${this.BASE_URL}/tickets/by-user/${userId}/${days}`;
    return this.http.get<MergeDevTicketingUserStats>(url);
  }

  getTicketStatsByUserBetweenDates(
    userId: number,
    periodStart: string,
    periodEnd: string
  ): Observable<MergeDevTicketingUserStats> {
    const url = `${this.BASE_URL}/tickets/by-user/${userId}/between/${periodStart}/${periodEnd}`;
    return this.http.get<MergeDevTicketingUserStats>(url);
  }

  getTicketsByUser(userId: number, days: number): Observable<MergeDevTicket[]> {
    const url = `${this.BASE_URL}/tickets/by-user/${userId}/${days}/full`;
    return this.http.get<MergeDevTicket[]>(url);
  }

  getCRMStatsByUser(userId: number): Observable<MergeDevCRMStats> {
    const url = `${this.BASE_URL}/crm-stats/by-user/${userId}`;
    return this.http.get<MergeDevCRMStats>(url);
  }

  getCRMStatsByUserBetweenDates(
    userId: number,
    periodStart: string,
    periodEnd: string
  ): Observable<MergeDevCRMStats> {
    const url = `${this.BASE_URL}/crm-stats/by-user/${userId}/between/${periodStart}/${periodEnd}`;
    return this.http.get<MergeDevCRMStats>(url);
  }

  getCRMStatsByUserInLastDays(
    userId: number,
    days: number
  ): Observable<MergeDevCRMStats> {
    const url = `${this.BASE_URL}/crm-stats/by-user/${userId}/${days}`;
    return this.http.get<MergeDevCRMStats>(url);
  }
}
