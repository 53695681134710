export class SwalMessages {

  public static readonly CANCEL_DEFAULT = 'swal.cancel-default';
  public static readonly CONFIRM_DEFAULT = 'swal.confirm-default';

  public static readonly CONFIRMATION_DEFAULT_TILE = 'swal.confirmation-default-title';
  public static readonly WARNING_DEFAULT_TITLE = 'swal.warning-default-title';
  public static readonly WARNING_DEFAULT_DESCRIPTION = 'swal.warning-default-description';
  public static readonly ERROR_DEFAULT_TITLE = 'swal.error-default-title';
  public static readonly ERROR_DEFAULT_DESCRIPTION = 'swal.error-default-description';
  public static readonly NO_OP_DEFAULT_TITLE = 'swal.no-op-default-title';
  public static readonly NO_OP_DEFAULT_DESCRIPTION = 'swal.no-op-default-description';
  public static readonly NO_OP_CONFIRM = 'swal.no-op-default-confirm';
  public static readonly NO_OP_CANCEL = 'swal.no-op-default-cancel';
  public static readonly WARNING_CONFIRMATION_INPUT_TITLE = 'swal.warning-confirmation-input.title';
  public static readonly WARNING_CONFIRMATION_INPUT_TEXT = 'swal.warning-confirmation-input.text';
  public static readonly WARNING_CONFIRMATION_INPUT_PLACEHOLDER = 'swal.warning-confirmation-input.placeholder'; 
  public static readonly WARNING_CONFIRMATION_INPUT_VALIDATION = 'swal.warning-confirmation-input.validation';
}