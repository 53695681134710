// TODO:
// - This would be way more efficient if we had an API call to get an array of goals (Pass an array of IDs to the server and get an array back)
// - Make the spellchecker ignore the rich UI contents (goal name, date, etc)
// - Change the auto-parsing function to use tinymce.dom instead of modifying the content as a string so that we can preserve the cursor position

import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import { GoalServerside } from '@app/models/goals/goal-serverside.model';
import { GoalStatus } from '@app/models/goals/goal-status.model';
import { Goal } from '@app/models/goals/goal.model';
import { FeatureLinking } from '@app/shared/components/rich-link-modal/rich-link-modal.component';
import moment from 'moment';

declare const tinymce: any;

interface Editor {
  insertContent: (content: string) => void;
  on: (eventName: string, handler: (e: any) => void) => void;
  windowManager: WindowManager;
  ui: UIRegistry;
  contentCSS: string[];
}

interface WindowManager {
  openUrl: (config: { title: string, url: string, onMessage: (api: any, data: any) => void, onCancel: () => void }) => void;
  close: () => void;
}

interface UIRegistry {
  registry: {
    addButton: (name: string, config: {
      text?: string;
      icon?: string;
      tooltip?: string;
      onAction: () => void;
    }) => void;
  };
}

interface EditorEvent<T = any> {
  type: string;
  target: T;
}

interface PluginMetadata {
  name: string;
  url: string;
}

interface Plugin {
  getMetadata?: () => PluginMetadata;
}

// #region - REGEX CONSTS
export const METADATA_REGEX = new RegExp(/{{2}[A-Z]+\|[0-9]+}{2}/g);
export const BRACKET_REGEX = new RegExp(/{{2}|}{2}/g);
export const RICH_LINK_REGEX = new RegExp(/<\w*>{{2}[A-Z]*\|[0-9]*}{2}<\/\w*>/g);
export const RICH_LINK_REGEX_NO_TAGS = new RegExp(/{{2}[A-Z]*\|[0-9]*}{2}/g);
// #endregion

// #region - INTERFACES, TYPES, CONSTANTS
export interface RichLinkMessage {
  feature: FeatureLinking;
  id: number;
  data: any;
}

export interface MceMessage {
  mceAction: string;
  content?: RichLinkMessage;
}

type EditorEventFn<T> = (event: EditorEvent<T>) => void;
type EditorXHRSuccessCallback = ((response: any) => void) | undefined;
type EditorXHRErrorCallback = (message: 'TIMED_OUT' | 'GENERAL', xhr: XMLHttpRequest | null, settings: any) => void;

// Request methods - Add as needed
enum EditorXHRMethod {
  GET = 'GET',
  POST = 'POST'
}

const STYLESHEET_PATH = './assets/css/richLink.css';
const DATE_FORMAT = 'D MMM YYYY';
const EVENTS_TO_PARSE = [
  'keyup',       
  'loadcontent',  
  'setcontent', 
  'beforesetcontent',  
];

const DATA_TEMP_STRING = '{{LINK_DATA}}';
// #endregion

// #region - UTILITY FUNCTIONS
function sendApiRequestWithAuth(
  url: string, 
  method: EditorXHRMethod, 
  success?: EditorXHRSuccessCallback, 
  error?: EditorXHRErrorCallback
): void {
  const token = `Bearer ${tinymce.util.LocalStorage.getItem('access_token')}`;

  const headers = new Headers();
  headers.append('Accept', 'application/json, text/plain, */*');
  headers.append('Authorization', token);

  fetch(url, {
    method: method,
    headers: headers
  })
  .then(async (res) => {
    if (!res.ok) {
      if (error) {
        error('GENERAL', null, {});
      }
      return;
    }
    const text = await res.text();
    const json = JSON.parse(text);
    if (success) success(json);
  })
  .catch(() => {
    if (error) {
      error('GENERAL', null, {});
    }
  });
}

function getEndpointForFeature(feature: FeatureLinking, id: number): string {
  if (typeof id !== 'number') {
    throw new Error('Invalid ID: ID must be a number');
  }
  switch (feature as FeatureLinking) {
    case FeatureLinking.GOALS:
      return `api/goal/${id}`;
  }
}

function parseStringToHtmlElement(input: string): Element {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.body.children[0];
}
// #endregion

// #region - UI GENERATION
function getGoalStatusClass(goal: Goal): string {
  if (goal.archived) {
    return 'status gray';
  }
  if (goal.complete) {
    return 'status blue';
  }
  switch (goal.status) {
    case GoalStatus.OFF_TRACK:
      return 'status red';
    case GoalStatus.PROGRESSING:
      return 'status yellow';
    case GoalStatus.ON_TRACK:
      return 'status green';
    default:
      return '';
  }
}

function getRichLinkWrapper(feature: FeatureLinking, id: number) {
  const rlMeta = `${feature}|${id}`;
  const output =
    `<span class="rich-link" data-placeholder="${rlMeta}" contenteditable="false" draggable="true">
        <span class="rl-meta">${rlMeta}</span>
        <span class="rl-data">{{LINK_DATA}}</span>
    </span>`;
  return output;
}

export function getGoalRichUI(goal: Goal, clickable: boolean = false): Element {
  let rlData = `
    <span class="${getGoalStatusClass(goal)}">&nbsp;</span>
    <span class="title">${goal.title}</span>
    <span class="pill yellow">${moment(goal.endDate).format(DATE_FORMAT)}</span>
    <span class="progress-bar-container">
      <span class="progress-bar-line progress-${goal.completionPercentage}">&nbsp;</span>
      <span class="progress-bar-text progress-text-${goal.completionPercentage}">${goal.completionPercentage}%</span>
    </span>
  `;
  if (clickable) {
    rlData = `<a href="/goals/individual/${goal.id}">${rlData}</a>`;
  }
  let output = getRichLinkWrapper(FeatureLinking.GOALS, goal.id);
  output = output.replace(DATA_TEMP_STRING, rlData);
  return parseStringToHtmlElement(output);
}

function getLoadingInsert(type: FeatureLinking, id: number): Element {
  const rlData =
    `<span class="rich-link-loading">
      <span class="icon loading">&nbsp;</span>
      <span>Loading data</span>
    </span>`;
  let output = getRichLinkWrapper(type, id);
  output = output.replace(DATA_TEMP_STRING, rlData);
  return parseStringToHtmlElement(output);
}

function getErrorInsert(type: FeatureLinking, id: number): Element {
  let errorMessage = `Failed to get item`;
  switch (type) {
    case FeatureLinking.GOALS:
      errorMessage = 'Failed to get goal information';
      break;
  }
  const rlData =
    `<span class="rich-link-error">
      <span class="icon error">&nbsp;</span>
      <span>${errorMessage}</span>
    </span>`;
  let output = getRichLinkWrapper(type, id);
  output = output.replace(DATA_TEMP_STRING, rlData);
  return parseStringToHtmlElement(output);
}
// #endregion

// #region - RICH UI ADDING
function getErrorCallback(
  feature: FeatureLinking, 
  id: number, 
  url: string, 
  method: EditorXHRMethod, 
  success: EditorXHRSuccessCallback, 
  loadingElem: Element
): EditorXHRErrorCallback {
  return (message, err) => {
    const errorElem = getErrorInsert(feature, id);
    errorElem.addEventListener('click', () => {
      tinymce.DOM.replace(loadingElem, errorElem, false);
      sendApiRequestWithAuth(url, method, success, getErrorCallback(feature, id, url, method, success, loadingElem));
    });
    tinymce.DOM.replace(errorElem, loadingElem, false);
  }
}


export function recursiveAddLinks(element: Element): void {
  // Skip elements that are already rich links.
  if (element.classList && element.classList.contains("rich-link")) {
    return;
  }
  
  if (element.children.length === 0) {
    if (element.textContent) {
      let matches = element.textContent.match(RICH_LINK_REGEX);
      if (!matches) {
        matches = element.textContent.match(RICH_LINK_REGEX_NO_TAGS);
      }
      if (matches && (matches.length > 0)) {
        convertFormatToSpan(element);
      }
    }
    return;
  }

  for (let index = 0; index < element.children.length; index++) {
    recursiveAddLinks(element.children[index]);
  }
}
// #endregion

function convertFormatToSpan(element: Element) {
  // If the element already contains a child with class 'rl-meta', skip conversion.
  if (element.querySelector('.rl-meta')) {
    return;
  }

  let text = element.textContent || '';
  let match = text.match(RICH_LINK_REGEX_NO_TAGS);

  while (match && match.length) {
    const placeholder = match[0]; // e.g. "{{GOALS|123}}"
    const bracketless = placeholder.replace(BRACKET_REGEX, ''); // "GOALS|123"
    const [featureStr, idStr] = bracketless.split('|');
    const feature = featureStr as FeatureLinking;
    const id = parseInt(idStr, 10);

    // Insert a loading element in place of the raw placeholder
    const loadingElem = getLoadingInsert(feature, id);
    text = text.replace(placeholder, loadingElem.outerHTML);
    element.innerHTML = text;

    // Now replace loading with final Rich UI
    switch (feature) {
      case FeatureLinking.GOALS:
        const url = getEndpointForFeature(feature, id);
        const method = EditorXHRMethod.GET;
        const success: EditorXHRSuccessCallback = (res: GoalServerside) => {
          let goal = new Goal(res);
          goal = Goal.getGoalCompletionPercentage(goal);
          const richUI = getGoalRichUI(goal);
          tinymce.activeEditor.getBody().querySelectorAll('.rich-link').forEach(el => {
            if (el.getAttribute('data-placeholder') === bracketless) {
              tinymce.activeEditor.dom.replace(richUI, el, false);
            }
          });
        };
        const error: EditorXHRErrorCallback = getErrorCallback(feature, id, url, method, success, loadingElem);
        sendApiRequestWithAuth(url, method, success, error);
        break;
    }

    // see if there's another placeholder in the updated text
    match = text.match(RICH_LINK_REGEX) || text.match(RICH_LINK_REGEX_NO_TAGS);
  }
}



// #region - RICH UI REMOVING
function convertSpanToFormat(element: Element): string {
  const metaData = element.children[0];
  const [feature, id] = metaData.innerHTML.split('|');
  return `<strong>{{${feature}|${id}}}</strong>`;
}

function recursiveRemoveLinks(element: Element): Element {
  if (element.children.length === 0) {
    return element;
  }
  for (let index = 0; index < element.children.length; index++) {
    if (element.children[index].classList.contains('rich-link')) {
      element.children[index].outerHTML = convertSpanToFormat(element.children[index]);
    } else {
      element.children[index].outerHTML = recursiveRemoveLinks(element.children[index]).outerHTML;
    }
  }
  return element;
}

export function richLinkRemoveLinks(content: string): string {
  if (content.length === 0) {
    return content;
  }
  const parser = new DOMParser();
  const contentHTML = parser.parseFromString(content, 'text/html');
  if (contentHTML.children.length > 0) {
    const htmlTag = contentHTML.children[0];
    if (htmlTag.children.length > 0) {
      let bodyTag = htmlTag.children[1];
      bodyTag = recursiveRemoveLinks(bodyTag);
      return bodyTag.innerHTML;
    }
  }
  return content;
}
// #endregion

// #region - MODAL CALLBACK
function parseMessageData(message: RichLinkMessage, editor: Editor) {
  switch (message.feature) {
    case FeatureLinking.GOALS:
      const goals: Goal[] = message.data as Goal[];
      const insertString = goals.map(g => getGoalRichUI(g).outerHTML).join(' ');
      editor.insertContent(insertString);
      break;
  }
}
// #endregion

// #region - CHANGE DETECTION
function getContentElementRefFromEvent(eventType: string, event: EditorEvent<any>): (Element | null) {
  switch (eventType) {
    case 'loadcontent':
    case 'beforesetcontent':
        return event.target.iframeElement?.contentDocument?.body || null;
    case 'keyup':
      return event.target;

    default:
      return null;
  }
}


function onEventFired(eventType: string, editor: Editor): EditorEventFn<any> {
  return (event: EditorEvent<any>) => {
    const contentRef = getContentElementRefFromEvent(eventType.toLowerCase(), event);
  if (contentRef) {
    recursiveAddLinks(contentRef);
  }
  }
}
// #endregion

// #region - PLUGIN SETUP
const pluginMetadata: Plugin = {
  getMetadata: () => {
    return {
      name: 'Frankli Rich Link',
      url: 'none'
    }
  }
};

function addPluginCSS(editor: Editor, domain: string) {
  if (!editor.contentCSS.includes(STYLESHEET_PATH)) {
    editor.contentCSS.push(`${domain}/assets/css/richLink.css`);
  }
}

function getDomain(url: string): string {
  const urlSplit = url.split('/');
  return `${urlSplit[0]}//${urlSplit[2]}`;
}

function registerEventListeners(editor: Editor): void {
  EVENTS_TO_PARSE.forEach(event => {
    editor.on(event, onEventFired(event, editor));
  });
}
// #endregion

export const RICH_LINK_PLUGIN = function (editor: Editor, url: string): Plugin { // NOTE: This breaks if it's an arrow function
  let richLinking = false;
  const domain = getDomain(url);
  addPluginCSS(editor, domain);
  registerEventListeners(editor);

  // #region - CORE FUNCTIONS
  const endRichLink = () => {
    richLinking = false;
  };

  const onSelectLink = (windowManager: any, data: MceMessage) => {
    if (data && data.mceAction && data.content) {
      switch (data.mceAction) {
        case 'insertRichLink':
          parseMessageData(data.content, editor);
          // fall through
        case 'closeRichLink':
        default:
          windowManager.close();
          richLinking = false;
      }
    }
    windowManager.close();
    endRichLink();
  };

  const onStartLink = () => {
    if (richLinking) {
      return;
    }
    richLinking = true;
    editor.windowManager.openUrl({
      title: '',
      url: `${domain}/rich-link`,
      onMessage: onSelectLink,
      onCancel: endRichLink
    });
  };
  // #endregion

  // #region - UI COMPONENTS
  editor.ui.registry.addButton('btnFrankliLink', {
    icon: 'bullseye',
    tooltip: 'Link to a goal in Frankli',
    onAction: onStartLink,
  });
  // #endregion

  return pluginMetadata;
};
