import { Pipe, PipeTransform } from '@angular/core';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '../api/goals.api.service';
import { FeatureLinking } from '../components/rich-link-modal/rich-link-modal.component';
import { BRACKET_REGEX, getGoalRichUI, METADATA_REGEX, RICH_LINK_REGEX } from '../tinymce/plugins/richLink.plugin';

@Pipe({
  name: 'richLinkDisplay'
})
export class RichLinkDisplayPipe implements PipeTransform {
  blacklist: string[];
  initialContent: string;

  constructor(
    private goalsAPIService: GoalsAPIService
  ) {
    this.blacklist = [];
    this.initialContent = '';
  }

  transform(value: any, args?: any): Promise<string> {
    this.initialContent = value;

    if (value && value.length > 0) {
      const stringsFound = this.getMatches(value);

      if (stringsFound.length > 0) {
        return this.parseStringsFromText(value, stringsFound)
      }
    }

    return Promise.resolve(value);
  }

  private getMatches(content: string): string[] {
    if (content.length === 0) {
      return [];
    }

    let stringsFound = content.match(RICH_LINK_REGEX) as string[] | null;
    if (!stringsFound) {
      return [];
    }

    stringsFound = (stringsFound || []).filter(s => !this.blacklist.includes(s));
    if (stringsFound.length === 0) {
      return [];
    }

    return stringsFound as string[];
  }

  private async replaceGoalString(id: number): Promise<string> {
    try {
      let g = await this.goalsAPIService.getGoalById(id).toPromise();
      g = Goal.getGoalCompletionPercentage(g);
      const richUI = getGoalRichUI(g, true).outerHTML;
      return richUI;
    } catch (e) {
      const val = `{{${FeatureLinking.GOALS}|${id}}}`;
      this.blacklist.push(val);
      return val;
    }
  }

  private async parseStringsFromText(content: string, matches: string[]): Promise<string> {
    for (let index = 0; index < matches.length; index++) {
      const match = matches[index];
      const metadata = match.match(METADATA_REGEX);

      if (metadata) {
        const [feature , id] = metadata[0].replace(BRACKET_REGEX, '').split('|');
        switch (feature as FeatureLinking) {
          case FeatureLinking.GOALS:
            const newItem = await this.replaceGoalString(+id);
            content = content.replace(match, newItem);
            break;
        }
      }
    }

    return content;
  }
}
