<div class="rich-text-editor" [ngClass]="columnIndent ? 'row' : ''">
  <div [ngClass]="columnIndent ? 'col-xs-10 col-xs-offset-1' : ''">
    <div class="editor-toggle" [class.editor-hidden]="loading">
      <ng-container *ngIf="!display; else templateDisplay">
        <div [class.error-border]="(wordcount >= dynamicMaxCharacters) || showErrorBorder">
          <editor
            name="editor"
            [(ngModel)]="value"
            [init]="editorConfig"
            [disabled]="disabled"
          ></editor>
        </div>

        <div
          class="text-right noselect"
          [class.text-danger]="(wordcount >= dynamicMaxCharacters) || warnWordcount || showErrorBorder"
        >
          {{ wordcount }} / {{ dynamicMaxCharacters }}
        </div>
      </ng-container>

      <ng-template #templateDisplay>
        <app-rich-display [text]="value"></app-rich-display>
      </ng-template>
    </div>

    <div *ngIf="loading">
      <div class="empty-state-container" [style.height.px]="height">
        <span class="spinner-container">
          <span class="fas fa-spin fa-spinner fa-2x fa-fw"></span>
        </span>
      </div>
    </div>
  </div>
</div>
