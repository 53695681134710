<div class="crm-sidebar-container">
  <ng-container *ngIf="!state.loading">
    <div class="sidebar-content-title">
      <span>{{subjectUser.firstName}}'s stats</span>
      <!-- <span>CRM stats</span> -->
      <!-- This image takes a bit long to load in, we shouldn't delay anything while waiting for it -->
      <ng-container *ngIf="integration != undefined">
        <img [src]="integration.image" />
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!state.loading; else stateLoading">
    <div class="filters">
      <span class="filter-label">Stats between:</span>
      <app-date-range-picker class="date-range-picker" [formControl]="controlDateRange" [name]="'dateperiod'"
        [maxSpan]="{years: 1}" [linkedCalendars]="false" [alwaysShowCalendars]="true"
        [openPickerEvent]="openDatepickerEvent" [ranges]="dateRangePickerPresets"></app-date-range-picker>
    </div>
    <ng-container *ngIf="hasData; else hasNoData">
      <ng-container *ngIf="crmStats.hasStats; else hasNoStats">
        <!-- Data Output -->
        <div class="metric-list">
          <div class="metric-item" (click)="expand.engagements = !expand.engagements">
            <div class="metric-item-top">
              <div class="metric-value">{{crmStats.engagementStats.totalCount}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-users"></span>
              </div>
            </div>
            <div class="metric-title">Total engagements</div>
            <div *ngIf="expand.engagements" class="metric-extra-info">
              <div class="list-item" *ngFor="let item of crmStats.engagementStats.breakdown">
                <div class="stat">
                  <div class="stat-title">{{item.engagementType | titlecase}}s:</div>
                  <div class="stat-value">{{item.count}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="metric-item" (click)="expand.tasks = !expand.tasks">
            <div class="metric-item-top">
              <div class="metric-value">{{crmStats.taskStats.totalCount}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-tasks"></span>
              </div>
            </div>
            <div class="metric-title">Total tasks</div>
            <div *ngIf="expand.tasks" class="metric-extra-info">
              <div class="list-item" *ngFor="let item of crmStats.taskStats.breakdown">
                <div class="stat">
                  <div class="stat-title">{{item.status | titlecase}}:</div>
                  <div class="stat-value">{{item.count}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="metric-item" (click)="expand.leads = !expand.leads">
            <div class="metric-item-top">
              <div class="metric-value">{{crmStats.leadsStats.totalCount}}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-filter"></span>
              </div>
            </div>
            <div class="metric-title">Total leads</div>
            <div *ngIf="expand.leads" class="metric-extra-info">
              <div class="list-item" *ngFor="let item of crmStats.leadsStats.breakdown">
                <div class="stat">
                  <div class="stat-title">{{item.source | titlecase}}: </div>
                  <div class="stat-value">{{item.count}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="metric-item" (click)="expand.opportunities = !expand.opportunities">
            <div class="metric-item-top">
              <div class="metric-value">{{ crmStats.opportunityStats.totalCount }}</div>
              <div class="metric-icon">
                <span class="fal fa-fw fa-briefcase"></span>
              </div>
            </div>
            <div class="metric-title">Total opportunities</div>
            <div *ngIf="expand.opportunities" class="metric-extra-info">
              <div class="list-item" *ngFor="let item of crmStats.opportunityStats.breakdownByStage">
                <div class="stat">
                  <div class="stat-title">{{ item.value | titlecase }}:</div>
                  <div class="stat-value">{{ item.count }}</div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </ng-container>
    </ng-container>
    <ng-template #hasNoStats>
      <div class="p-top-15 p-bottom-15 text-center">
        <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
        <br />
        <span>
          {{subjectUser.firstName}} has no CRM data for the selected period.
        </span>
      </div>
    </ng-template>
    <ng-template #hasNoData>
      <div class="p-top-15 p-bottom-15 text-center">
        <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
        <br />
        <span>No CRM data available.
          <br>This could be because {{subjectUser.firstName}}'s data hasn't synced yet or because
          {{subjectUser.firstName}} doesn't have a CRM account in your company.
        </span>
      </div>
    </ng-template>
  </ng-container>

  <!-- #region - Alt states -->
  <!-- TODO: Use component -->
  <ng-template #stateLoading>
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>

  <!-- TODO: Use component -->
  <ng-template #stateError>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br />
      <span>An error occurred loading this information</span>
    </div>
  </ng-template>
  <!-- #endregion -->
</div>