import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Competency } from '../model/competency.model';
import { CreateCompetencyDto } from '../model/create-competency.model';
import { ICompetencyAPI } from './competency-api.model';
import { CompetencyRating, ICompetencyRatingServerside } from '../model/competency-rating.model';
import { map } from 'rxjs/operators';
import { CreateCompetencyRating } from '../model/create-competency-rating.model';
import { CompetencyRatingConfiguration, ICompetencyRatingConfigurationServerSide } from '../model/competency-rating-configuration.model';
import { CreateCompetencyRatingConfiguration } from '../model/create-competency-rating-configuration.model';

@Injectable()
export class CompetencyAPIService implements ICompetencyAPI {
  private readonly BASE_URL = 'api/competency';

  constructor(
      private http: HttpClient
  ) {
  }

  get(ids: number[], name: string, categories: string[]): Observable<Competency[]> {
    const url = `${this.BASE_URL}`;

    let params = new HttpParams();

    if (ids) {
      ids.forEach(id => {
        params = params.append('id', id);
      });
    }

    if (categories) {
      categories.forEach(category => {
        params = params.append('category', category);
      });
    }

    if (name && name.length > 0) {
      params = params.append('name', name);
    }

    return this.http.get<Competency[]>(url, { params: params });
  }

  getForUserId(userId: number): Observable<Competency[]> {
    const url = `${this.BASE_URL}/user/${userId}`;
    return this.http.get<Competency[]>(url);
  }

  create(competency: CreateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Competency>(url, competency);
  }

  update(id: number, competency: CreateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Competency>(url, competency);
  }

  delete(id: number): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Competency>(url);
  }

  deleteMultiple(competencyIds: number[]): Observable<Competency[]> {
    const url = `${this.BASE_URL}/delete`;
    return this.http.post<Competency[]>(url, competencyIds);
  }

  /**
   * Imports file with
   * @param file CSV files in the format 'Category','Name','Description',
   * @returns Competency[]
   */
  importCompetencies(file: File): Observable<Competency[]> {
    const url = `${this.BASE_URL}/import`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Competency[]>(url, formData);
  }

  downloadCSV(): Observable<Blob> {
    const url = `${this.BASE_URL}/download-csv`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getAllRatings(): Observable<CompetencyRating[]> {
    const url = `${this.BASE_URL}/ratings`;
    return this.http.get<ICompetencyRatingServerside[]>(url).pipe(map(s => s.map(s => new CompetencyRating(s))));
  }

  getRatingsForUser(userId: number): Observable<CompetencyRating[]> {
    const url = `${this.BASE_URL}/ratings/by-user/${userId}`;
    return this.http.get<ICompetencyRatingServerside[]>(url).pipe(map(s => s.map(s => new CompetencyRating(s))));
  }

  getRatingByTargetId(targetId: number): Observable<CompetencyRating[]> {
    const url = `${this.BASE_URL}/ratings/${targetId}`;
    return this.http.get<ICompetencyRatingServerside[]>(url).pipe(map(s => s.map(s => new CompetencyRating(s))));
  }

  createRating(createDto: CreateCompetencyRating): Observable<CompetencyRating> {
    const url = `${this.BASE_URL}/ratings`;
    return this.http.post<ICompetencyRatingServerside>(url, createDto).pipe(map(s => new CompetencyRating(s)));
  }

  deleteRating(id: number): Observable<CompetencyRating> {
    const url = `${this.BASE_URL}/ratings/${id}`;
    return this.http.delete<ICompetencyRatingServerside>(url).pipe(map(s => new CompetencyRating(s)));
  }

  getRatingConfiguration(): Observable<CompetencyRatingConfiguration> {
    const url = `${this.BASE_URL}/ratings/configuration`;
    return this.http.get<ICompetencyRatingConfigurationServerSide>(url).pipe(map(s => {
      if (s === null) {
        return CompetencyRatingConfiguration.DEFAULT;
      } else {
        return new CompetencyRatingConfiguration(s);
      }
    }));
  }

  updateRatingConfiguration(createDto: CreateCompetencyRatingConfiguration): Observable<CompetencyRatingConfiguration> {
    const url = `${this.BASE_URL}/ratings/configuration`;
    return this.http.post<ICompetencyRatingConfigurationServerSide>(url, createDto).pipe(map(s => new CompetencyRatingConfiguration(s)));
  }

}
