import Swal, {SweetAlertOptions, SweetAlertResult} from 'sweetalert2';
import {SwalDesktopClasses, SwalMobileClasses} from '@app/shared/utils/swal-custom-classes';
import {Globals} from '../globals/globals';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {SwalMessages} from '@app/constants/swal.messages';
import {CommonMessages} from '@app/constants/common.messages';

type SweetAlertType = (
  'SUCCESS' |
  'SUCCESS_CONFIRMATION' |
  'WARNING' |
  'WARNING_CONFIRMATION' |
  'WARNING_CONFIRMATION_INPUT' |  
  'MOCK_NO_OP' |
  'ERROR' |
  null
);

@Injectable()
export class SwalUtils {

  imageSize = 140;

  baseClass = SwalMobileClasses;

  constructor(
        public globals: Globals,
        private translateService: TranslateService
  ) {
    const onMobile = globals.checkIsMobile();
    this.baseClass = (onMobile ? SwalMobileClasses : SwalDesktopClasses);
  }


  private getDefaults(type: SweetAlertType, options?: SweetAlertOptions): SweetAlertOptions {
    
    // Initialize with default swal params
    let settings: SweetAlertOptions = {
      title: '',
      text: '',
      imageWidth: this.imageSize,
      imageHeight: this.imageSize,
      confirmButtonColor: '#30747F',
      cancelButtonText: SwalMessages.CANCEL_DEFAULT,
      confirmButtonText: SwalMessages.CONFIRM_DEFAULT,
      customClass: this.baseClass
    };

    // Patch with type dependant settings
    switch (type) {
      case 'SUCCESS':
        settings = {
          ...settings,
          imageUrl: 'assets/img/swal-icons/frankli-success-icon.svg'
        };
        break;
      case 'SUCCESS_CONFIRMATION':
        settings = {
          ...settings,
          title: SwalMessages.CONFIRMATION_DEFAULT_TILE,
          imageUrl: 'assets/img/swal-icons/frankli-success-icon.svg',
          showCancelButton: true,
          reverseButtons: true
        };
        break;
      case 'WARNING':
        settings = {
          ...settings,
          title: SwalMessages.WARNING_DEFAULT_TITLE,
          text: SwalMessages.WARNING_DEFAULT_DESCRIPTION,
          imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg'
        };
        break;
      case 'WARNING_CONFIRMATION':
        settings = {
          ...settings,
          title: SwalMessages.CONFIRMATION_DEFAULT_TILE,
          imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
          confirmButtonText: CommonMessages.CONFIRM,
          showCancelButton: true,
          reverseButtons: true
        };
        break;
        case 'WARNING_CONFIRMATION_INPUT':
          settings = {
            ...settings,
            title: SwalMessages.WARNING_CONFIRMATION_INPUT_TITLE,   
            text: SwalMessages.WARNING_CONFIRMATION_INPUT_TEXT,    
            imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
            confirmButtonText: SwalMessages.CONFIRM_DEFAULT,        
            showCancelButton: true,
            reverseButtons: true,
            input: 'text',
            inputPlaceholder: SwalMessages.WARNING_CONFIRMATION_INPUT_PLACEHOLDER
          };
          break;
      case 'MOCK_NO_OP':
        settings = {
          ...settings,
          title: SwalMessages.NO_OP_DEFAULT_TITLE,
          text: SwalMessages.NO_OP_DEFAULT_DESCRIPTION,
          imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: SwalMessages.NO_OP_CONFIRM,
          cancelButtonText: SwalMessages.NO_OP_CANCEL
        };
        break;
      case 'ERROR':
        settings = {
          ...settings,
          title: SwalMessages.ERROR_DEFAULT_TITLE,
          text: SwalMessages.ERROR_DEFAULT_DESCRIPTION,
          imageUrl: 'assets/img/swal-icons/frankli-error-icon.svg'
        };
        break;
    }

    // Patch with user provided settings
    settings = {
      ...settings,
      ...options
    };

    settings = this.parseOptions(settings);

    return settings;
  }

  parseOptions(options: SweetAlertOptions): SweetAlertOptions {
    if (options.title && (typeof options.title === 'string')) {
      options.title = this.translateService.instant(options.title);
    }

    if (options.text && (typeof options.text === 'string')) {
      options.text = this.translateService.instant(options.text);
    }

    if (options.cancelButtonText) {
      options.cancelButtonText = this.translateService.instant(options.cancelButtonText);
    }

    if (options.confirmButtonText) {
      options.confirmButtonText = this.translateService.instant(options.confirmButtonText);
    }

    if (options.inputPlaceholder && typeof options.inputPlaceholder === 'string') {
      options.inputPlaceholder = this.translateService.instant(options.inputPlaceholder);
    }

    return options;
  }

  displayBaseSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults(null, options));

  displayErrorSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('ERROR', options));

  displayWarning = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('WARNING', options));

  displayWarningConfirmationSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('WARNING_CONFIRMATION', options));

  // TODO: Replace this with a call to the warning confirmation swal w/custom parameters instead
  displayMockNoOpSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('MOCK_NO_OP', options));

  displaySuccessSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('SUCCESS', options));

  displaySuccessConfirmationSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => Swal.fire(this.getDefaults('SUCCESS_CONFIRMATION', options));

  displayWarningConfirmationInputSwal(options?: SweetAlertOptions): Promise<SweetAlertResult> {
    const baseSettings = this.getDefaults('WARNING_CONFIRMATION_INPUT', options);
  
    const typedWord: string = baseSettings.inputPlaceholder as string;
  
    baseSettings.preConfirm = (value) => {
      if (value !== typedWord) {
        Swal.showValidationMessage(
          this.translateService.instant(SwalMessages.WARNING_CONFIRMATION_INPUT_VALIDATION, { confirmText: typedWord })
        );
        return false;  
      }
      return true;
    };
  
    return Swal.fire(baseSettings);
  }
}
