<div class="container-private-notes">
  <ng-container *ngIf="privateNotes || canAddNotes; else templateEmptyState;">
    <ng-container *ngIf="privateNotes">
      <ng-container *ngIf="!state.addingNote || hasOtherUserNotes">
        <div class="area-private-notes-list">
          <div class="wrapper-note-item">
            <div class="note-item-picture" *ngIf="globals.user">
              <app-profile-photo [src]="globals.user.imageUrl" [firstName]="globals.user.firstName" [lastName]="globals.user.lastName" [width]="'30px'"></app-profile-photo>
            </div>
            <div class="note-item-contents">
              <div class="note-item-text">
                <app-rich-display [text]="privateNotes.contents"></app-rich-display>
              </div>
              <div class="note-item-actions">
                <ng-container *ngIf="canAddNotes">
                  <app-table-action-icon [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" (click)="startAddingNote()"></app-table-action-icon>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    
    <ng-container *ngIf="!state.noteAlreadyAdded || state.addingNote">
      <div class="area-add-note">
        <ng-container *ngIf="state.addingNote; else templatePrompToAddNote;">
          <div class="frankli-form-group frankli-form-group-filled">
            <app-editor-response [formControl]="controlNotes" [maxCharacters]="1000" [hardMax]="2000" [height]="globals.onMobile? 200 : 300" [showErrorBorder]="" [toolbar]="editorToolbar" [columnIndent]="false"></app-editor-response>
          </div>
          <div class="text-right p-top-15">
            <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelAddingNote()">{{eCommonMessages.CANCEL | translate}}</app-button>
            <span class="m-right-5"></span>
            <app-button [type]="eButtonType.GREEN" (onClick)="submitAddingNote()" [disabled]="!controlNotes.value || controlNotes.value.trim().length === 0">{{eCommonMessages.SUBMIT | translate}}</app-button>
          </div>
        </ng-container>
        <ng-template #templatePrompToAddNote>
          <div class="area-prompt-to-note" (click)="startAddingNote()">
            <span class="fal fa-fw fa-note"></span>
            <span>{{eOneToOneMessages.CTA_WRITE_NOTE | translate}}</span>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #templateEmptyState>
    <app-empty-state [message]="'No private notes'" [height]="'200px'"></app-empty-state>
  </ng-template>
</div>
