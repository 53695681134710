export class CompetencyRating implements ICompetencyRating {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(serverSide: ICompetencyRatingServerside) {
    this.id = serverSide.id;
    this.sourceUserId = serverSide.sourceUserId;
    this.targetUserId = serverSide.targetUserId;
    this.targetEntityId = serverSide.targetEntityId;
    this.scoreValue = serverSide.scoreValue;
    this.scoreComment = serverSide.scoreComment;
    this.archived = serverSide.archived;
    this.createdAt = new Date(serverSide.createdAt);
    this.updatedAt = new Date(serverSide.updatedAt);
  }

}

export interface ICompetencyRating {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: Date;
  updatedAt: Date;
}


export interface ICompetencyRatingServerside {
  id: number;
  sourceUserId: number;
  targetUserId: number;
  targetEntityId: number;
  scoreValue: number;
  scoreComment: string;
  archived: boolean;
  createdAt: string;
  updatedAt: string;
}

