import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {environment} from 'environments/environment';
import {Globals} from './shared/globals/globals';
import {StatusAPIService} from './shared/api/status.api.service';
import {WebsocketService} from './shared/api/websocket.service';
import {SidebarService} from './shared/sidebar/sidebar.service';
import { I18nService } from '@app/i18n/i18n.service';
import { CustomJQuery } from './models/custom-jquery.model';
import { IntercomService } from './shared/utils/intercom.service';
import { SentryService } from './shared/utils/sentry.service';

declare var $: CustomJQuery;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly mobileRegex: RegExp = new RegExp(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i);
  readonly mobileRegex2: RegExp = new RegExp(/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i);

  public lazyLoading = false;

  constructor(
    private router: Router,
    private angulartics2Amplitude: Angulartics2Amplitude,
    public globals: Globals,
    private statusAPIService: StatusAPIService,
    private websocketService: WebsocketService,
    public sidebarService: SidebarService,
    private i18nService: I18nService,
    private intercomService: IntercomService,
    private sentryService: SentryService
  ) {
    if (environment.production && !environment.mock.enabled) {
      this.angulartics2Amplitude.startTracking();
      switch (this.router.url) {
        case '/':
        case '/auth/login':
        case '/auth/reset-password':
        case '/auth/forgot-password':
        case '/callback':
        case '/rich-link':
        case '/teams-gateway':
        case '/archived':
        case '/revoked':
          this.websocketService.deactivate();
          break;
        default:
          this.websocketService.activate();
          break;
      }
    }

    this.initNotifyDefaults();
    this.intercomService.init();
  }

  ngOnInit(): void {
    // Setup translations
    this.i18nService.init('en', ['en', 'es-latam', 'de']);

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationEnd:
          // TODO: This will assume the user is logged in if they navigate to an invalid page
          // Change it to run after the user logs in instead of by page
          // Eg. localhost:4200/asdasda
          // we use startsWith to ensure pages like /reset#example etc gets caught and doesn't error
          if (this.router.url === '/'
              || this.router.url.startsWith('/auth/login')
              || this.router.url.startsWith('/auth/reset-password')
              || this.router.url.startsWith('/auth/forgot-password')
              || this.router.url.startsWith('/callback')
              || this.router.url.startsWith('/teams-gateway')
              || this.router.url.startsWith('/external-survey')
              || this.router.url.startsWith('/external-feedback')
              || this.router.url.startsWith('/plugin')
              || this.router.url.startsWith('/archived')
              || this.router.url.startsWith('/revoked')) {
            this.websocketService.deactivate();
          } else {
            this.websocketService.activate();
            this.intercomService.init();
            this.sentryService.initAuthenticatedUser(this.globals.user);
          }
          break;
        // if chunk can't be loaded - get status to determine reason
        case event instanceof NavigationError:
          if ((event as NavigationError).error.message.startsWith('Loading chunk')) {
            this.statusAPIService.getStatus().subscribe();
          }
          break;
        case event instanceof RouteConfigLoadStart:
          this.lazyLoading = true;
          break;
        case event instanceof RouteConfigLoadEnd:
          this.lazyLoading = false;
          break;
      }
    });
  }

  initNotifyDefaults(): void {
    // If JQuery isn't loaded, retry in 10 seconds
    if (typeof $ === 'undefined') {
      setTimeout(() => this.initNotifyDefaults(), 10000);
      return;
    }

    $.notifyDefaults({
      icon: 'fal fa-check-circle',
      offset: 20,
      spacing: 10,
      delay: 2000,
      timer: 50,
      z_index: 1550,
      newest_on_top: true,
      showProgressbar: false,
      type: 'frankli-blue',
      placement: {
        from: 'top',
        align: 'right'
      },
      mouse_over: null,
      animate: {
        enter: 'animated fadeInDown',
        exit: 'animated fadeOutUp'
      },
      onShow: null,
      onShown: null,
      onClose: null,
      onClosed: null,
      icon_type: 'class',
      template: `
        <div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">
          <button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>
          <span data-notify="icon"></span>
          <span data-notify="title">{1}</span>
          <span data-notify="message">{2}</span>
          <div class="progress" data-notify="progressbar">
            <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
          </div>
          <a href="{3}" target="{4}" data-notify="url"></a>
        </div>`
    });
  }

}
