<div class="container-talking-point-item">
  <ng-container *ngIf="!editing; else editingTalkingPoint;">
    <ng-container *ngIf="canReorder">
      <div class="area-drag-handle">
        <!-- This is a workaround to get the drag/drop library to work within a component -->
        <ng-content></ng-content>
        <span class="fal fa-fw fa-ellipsis-v"></span>
      </div>
    </ng-container>

    <div class="area-main-information">
      <div class="display-talking-point-title">
        <span [innerHTML]="(talkingPoint.title | linkify)"></span>
      </div>

      <ng-container *ngIf="displayComments && (canComment || hasComments)">
        <div class="area-talking-point-comments">
          <ng-container *ngIf="hasComments">
            <div class="area-comments-preview" (click)="toggleCommentList()">
              <span>{{eCommonMessages.COMMENT_COUNT(talkingPoint.comments.length) | translate: { count: talkingPoint.comments.length } }}</span>
              <span class="fal fa-fw fa-caret-down" [class.flip-v]="state.viewingComments"></span>
              <app-person-picture-list [users]="usersWithComments" [imageSizePx]="14"></app-person-picture-list>
            </div>
  
            <ng-container *ngIf="state.viewingComments && (!state.addingComment || hasOtherUserComments)">
              <div class="area-comments-list">
                <ng-container *ngFor="let commentItem of commentsOrdered; index as index;">
                  <ng-container *ngIf="(commentItem.userId !== globals.user.id) || !state.addingComment">
                    <div class="wrapper-comment-item">
                      <div class="comment-item-picture">
                        <ng-container *ngIf="commentItem.user; else templateCommentNoUser">
                          <app-profile-photo [src]="commentItem.user.imageUrl" [firstName]="commentItem.user.firstName" [lastName]="commentItem.user.lastName" [width]="'30px'"></app-profile-photo>
                        </ng-container>
                        <ng-template #templateCommentNoUser>
                          <ng-container *ngIf="globals.user">
                            <app-profile-photo [src]="globals.user.imageUrl" [firstName]="globals.user.firstName" [lastName]="globals.user.lastName" [width]="'30px'"></app-profile-photo>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="comment-item-contents">
                        <div class="comment-item-text">
                          <app-rich-display [text]="commentItem.contents"></app-rich-display>
                        </div>
                        <ng-container *ngIf="commentItem.userId === globals.user.id">
                          <div class="comment-item-actions">
                            <app-table-action-icon [icon]="'fa-edit'" [hoverColor]="eIconHoverColor.YELLOW" (click)="startAddingComment()"></app-table-action-icon>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
  
          <ng-container *ngIf="canComment">
            <ng-container *ngIf="!currentUserHasComment || state.addingComment">
              <div class="area-add-comment">
                <ng-container *ngIf="state.addingComment; else templatePrompToAddComment;">
                  <div class="frankli-form-group frankli-form-group-filled">
                    <app-editor-response [formControl]="commentForm" [maxCharacters]="1000" [hardMax]="2000" [height]="globals.onMobile? 200 : 200" [showErrorBorder]="" [toolbar]="editorToolbar" [columnIndent]="false"></app-editor-response>
                  </div>
                  <div class="text-right p-top-15">
                    <app-button [type]="eButtonType.GRAY_INVERTED" (onClick)="cancelAddingComment()">{{eCommonMessages.CANCEL | translate}}</app-button>
                    <span class="m-right-5"></span>
                    <app-button [type]="eButtonType.GREEN" (onClick)="submitAddingComment()" [disabled]="!commentForm.value || commentForm.value.trim().length === 0">{{eCommonMessages.SUBMIT | translate}}</app-button>
                  </div>
                </ng-container>
                <ng-template #templatePrompToAddComment>
                  <div class="area-prompt-to-comment" (click)="startAddingComment()">
                    <span class="fal fa-fw fa-comment"></span>
                    <span>{{eCommonMessages.CTA_WRITE_COMMENT | translate}}</span>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="area-action-icons">
      <ng-container *ngIf="talkingPoint.sourceUserId === globals.user.id">
        <ng-container *ngIf="!canBump; else bumpOptions">
          <ng-container *ngIf="canEdit">
            <app-table-action-icon [icon]="'fa-edit'" (click)="editStart()" [tooltipText]="'Edit this talking point'"></app-table-action-icon>
          </ng-container>
        </ng-container>
        <ng-template #bumpOptions>
          <app-table-action-icon [icon]="'fa-arrow-up'" (click)="tryBump()" [tooltipText]="'Add talking point to current meeting\'s agenda'"></app-table-action-icon>
          <app-table-action-icon [icon]="'fa-times'" (click)="tryHide()" [tooltipText]="'Hide this talking point'"></app-table-action-icon>
        </ng-template>
        <ng-container *ngIf="canAddOrRemove">
          <app-table-action-icon [icon]="'fa-times'" (click)="tryRemove()" [tooltipText]="'Remove this talking point'"></app-table-action-icon>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="canAction">
        <app-checkbox-input [formControl]="checkboxForm"></app-checkbox-input>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #editingTalkingPoint>
    <div class="area-edit-form">
      <div class="frankli-form-group" [class.frankli-form-group-filled]="controlEditTitle.value">
        <input type="text" class="form-control" [formControl]="controlEditTitle" (keyup)="onEditKeyup($event)" #controlEditTitleInput/>
        <div class="frankli-form-group-alert" *ngIf="controlEditTitle.invalid">
          <div *ngIf="controlEditTitle.hasError('minlength') || controlEditTitle.hasError('maxlength')">{{eCommonMessages.FORM_CHARACTER_LENGTH_BETWEEN | translate: { minCharacters: MIN_TP_TITLE_LENGTH, maxCharacters: MAX_TP_TITLE_LENGTH } }}</div>
        </div>
      </div>

      <div class="col-edit-actions">
        <app-table-action-icon [icon]="'fa-times'" (click)="editCancel()" [tooltipText]="'Discard changes'"></app-table-action-icon>
        <app-table-action-icon [icon]="'fa-check'" (click)="editSubmit()" [tooltipText]="'Confirm changes'"></app-table-action-icon>
      </div>
    </div>
  </ng-template>
</div>