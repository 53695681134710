import { CommonMessages } from '@app/constants/common.messages';
import { TranslateService } from '@ngx-translate/core';
import { IMyDate } from 'angular-mydatepicker';
import moment from 'moment';
import { Moment } from 'moment';
import { LocaleDateDifferenceMessages } from '../pipes/locale-date-difference/locale-date-difference.messages';

// TODO: Convert to non-static injectible service so we don't have to pass in translateservice each time
export class DateUtils {
  public static DEFAULT_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm[:00.000Z]';

  static getNumberOfDaysUntil = (date: Date | string | Moment): number => {
    const targetDate = moment(date).startOf('day');
    const today = moment().startOf('day');
    return targetDate.diff(today, 'day');
  };

  static isWithin30DaysOrNull = (date: Date): boolean => {
    if (date) {
      return DateUtils.getNumberOfDaysUntil(date) <= 30;
    }
    return true;
  };

  static convertDateToPickerFormat(date: Date): IMyDate {
    if (!date) { undefined!; }
    return {
      day: date.getDate(),
      month: (date.getMonth() + 1),
      year: date.getFullYear(),
    };
  }

  static convertDateMomentToPickerFormat(date: moment.Moment): IMyDate {
    if (!date) { undefined!; }
    const dateJS = date.toDate();
    return {
      day: dateJS.getDate(),
      month: (dateJS.getMonth() + 1),
      year: dateJS.getFullYear(),
    };
  }

  static convertPickerFormatToDate(value: IMyDate): Date {
    return new Date(value.year,  (value.month - 1), value.day, 0, 0, 0, 0);
  }

  public static getDaysRemaining(translateService: TranslateService, endDate: string | Date): string {
    const dueDate = moment(endDate).startOf('day');
    const today = moment().startOf('day');

    const days = dueDate.diff(today, 'days');


    if (days === 0) {
      return translateService.instant(CommonMessages.DUE_TODAY);
    }

    if (days === 1) {
      const timeUnitValue = translateService.instant(LocaleDateDifferenceMessages.DAY);
      return translateService.instant(CommonMessages.DUE_IN, { timeUnitValue });
    }

    if (days > 1) {
      const timeUnitValue = translateService.instant(LocaleDateDifferenceMessages.DAYS, { unitValue: days });
      return translateService.instant(CommonMessages.DUE_IN, { timeUnitValue });
    }

    if (days === -1) {
      const timeUnitValue = translateService.instant(LocaleDateDifferenceMessages.DAY);
      return translateService.instant(CommonMessages.OVERDUE_BY, { timeUnitValue });
    }

    if (days < -1) {
      const timeUnitValue = translateService.instant(LocaleDateDifferenceMessages.DAYS, { unitValue: (days * -1) });
      return translateService.instant(CommonMessages.OVERDUE_BY, { timeUnitValue });
    }

    return String(days);
  }

  public static getDaysRemainingTotal(endDate: string | Date): number {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    return a.diff(b, 'days');
  }

  public static setTimezoneToUTCButKeepDate(date?: Date | string | moment.Moment): Date {
    return moment(date).utc(true).toDate();
  }

  /**
  * This method will take a date and a timezone and convert the date to UTC.
  * @param {date} dateValue - The date to convert to UTC  
  * @param {string} timezone - The timezone the provided date is in
  **/
  public static parseDateTimeToUTC(dateValue: string | Date | moment.Moment, timezone: string): moment.Moment {
    // Intiialize the start date as a moment object. Assume the date is in the provided timezone.
    const startDateMoment = moment(dateValue).tz(timezone, true);

    // Convert the start date to UTC
    return startDateMoment.utc();
  }

  /**
  * This method will take a date and a timezone and convert the date to the given timezone.
  * @param {date} dateValue - The date to convert
  * @param {string} timezone - The timezone to convert to
  **/
  public static parseDateTimeToTimezone(dateValue: string | Date | moment.Moment, timezone: string): moment.Moment {
    // Intiialize the start date as a moment object
    const startDateMoment = moment(dateValue);

    // Convert the start date to the specified timezone
    return startDateMoment.tz(timezone);
  }

  /**
  * This method will take a date and a timezone and convert the date to the users local timezone.
  * @param {date} dateValue - The date to convert
  * @param {string} timezone - The timezone to convert to
  **/
  public static parseDateTimeToLocalTimezone(dateValue: string | Date | moment.Moment, timezone: string): moment.Moment {
    return moment(dateValue).tz(timezone).local(true);
  }

  /**
   * Fiscal years are stored with the year of 1900. This method will take a fiscal year and a target date and return the start of the fiscal year that the target date falls in.
   * @param fiscalYear - The fiscal year we have stored
   * @param targetDate (Optional) - The date to compare against. Defaults to the current date.
   * @returns The start of the fiscal year that the given date falls in
   */
  public static getStartOfFiscalYear(fiscalYear: Date, targetDate = new Date()): Date {
    const result = new Date(fiscalYear); // 1st April 1900

    const fiscalYearStartDay = result.getDate(); // 1st
    const fiscalYearStartMonth = result.getMonth(); // April

    const currentYear = targetDate.getFullYear();
    const currentMonth = targetDate.getMonth();
    const currentDay = targetDate.getDate();

    // If we are in the fiscal year then we need to go back a year
    if (currentMonth < fiscalYearStartMonth || (currentMonth === fiscalYearStartMonth && currentDay < fiscalYearStartDay)) {
      result.setFullYear(currentYear - 1);
    } else {
      result.setFullYear(currentYear);
    }

    result.setMonth(fiscalYearStartMonth);
    result.setDate(fiscalYearStartDay);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    result.setMilliseconds(0);

    return result;
  }

  public static getEndOfFiscalYear(fiscalYear: Date, targetDate = new Date()): Date {
    const start = this.getStartOfFiscalYear(fiscalYear, targetDate);
  
    const end = new Date(start);
    end.setFullYear(end.getFullYear() + 1);
    end.setDate(end.getDate() - 1);
  
    end.setHours(23, 59, 59, 999);
  
    return end;
  }  
}