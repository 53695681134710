import { ICompetencyRatingOption, ICompetencyRatingOptionServerSide, CompetencyRatingOption } from './competency-rating-option.model';

export class CompetencyRatingConfiguration implements ICompetencyRatingConfiguration {
  id: number;
  scoreCount: number;
  scoreIcon: string;
  options: CompetencyRatingOption[];
  createdAt: Date;
  updatedAt: Date;

  constructor(serverside: ICompetencyRatingConfigurationServerSide) {
    this.id = serverside.id;
    this.scoreCount = serverside.scoreCount;
    this.scoreIcon = serverside.scoreIcon;
    this.options = serverside.options.map(option => new CompetencyRatingOption(option)).sort((a, b) => a.scoreValue - b.scoreValue);
    this.createdAt = new Date(serverside.createdAt);
    this.updatedAt = new Date(serverside.updatedAt);
  }

  public static DEFAULT_5_PART: CompetencyRatingConfiguration = new CompetencyRatingConfiguration({
    scoreCount: 5,
    scoreIcon: 'fa-star',
    options: [
      { id: null, scoreValue: 0, name: 'Not demonstrated', description: 'The employee does not exhibit this competency in their role.' },
      { id: null, scoreValue: 1, name: 'Basic understanding', description: 'The employee has a basic understanding of this competency but requires supervision.' },
      { id: null, scoreValue: 2, name: 'Developing', description: 'The employee demonstrates this competency to a satisfactory level but is still developing.' },
      { id: null, scoreValue: 3, name: 'Proficient', description: 'The employee demonstrates this competency independently and effectively.' },
      { id: null, scoreValue: 4, name: 'Expert', description: 'The employee is an expert in this competency, often mentoring others and improving processes.' }
    ],
    id: null,
    createdAt: null,
    updatedAt: null
  });
  public static DEFAULT_4_PART: CompetencyRatingConfiguration = new CompetencyRatingConfiguration({
    scoreCount: 4,
    scoreIcon: 'fa-star',
    options: [
      { id: null, scoreValue: 0, name: 'Not demonstrated', description: 'The employee does not exhibit this competency in their role.' },
      { id: null, scoreValue: 1, name: 'Developing', description: 'The employee demonstrates a basic understanding of this competency but requires supervision and further development.' },
      { id: null, scoreValue: 2, name: 'Proficient', description: 'The employee demonstrates this competency independently and effectively.' },
      { id: null, scoreValue: 3, name: 'Expert', description: 'The employee is an expert in this competency, often mentoring others and improving processes.' }
    ],
    id: null,
    createdAt: null,
    updatedAt: null
  });
  public static DEFAULT_3_PART: CompetencyRatingConfiguration = new CompetencyRatingConfiguration({
    scoreCount: 3,
    scoreIcon: 'fa-star',
    options: [
      { id: null, scoreValue: 0, name: 'Developing', description: 'The employee demonstrates a basic understanding of this competency but requires supervision and further development.' },
      { id: null, scoreValue: 1, name: 'Proficient', description: 'The employee demonstrates this competency independently and effectively.' },
      { id: null, scoreValue: 2, name: 'Expert', description: 'The employee is an expert in this competency often mentoring others and improving processes.' }
    ],
    id: null,
    createdAt: null,
    updatedAt: null
  });

  public static DEFAULT: CompetencyRatingConfiguration = CompetencyRatingConfiguration.DEFAULT_3_PART;
  public static DEFAULT_OPTIONS: CompetencyRatingConfiguration[] = [CompetencyRatingConfiguration.DEFAULT_3_PART, CompetencyRatingConfiguration.DEFAULT_4_PART, CompetencyRatingConfiguration.DEFAULT_5_PART];

  public getOption(scoreValue: number): CompetencyRatingOption {
    return this.options.find(option => option.scoreValue === scoreValue);
  }

}

export interface ICompetencyRatingConfiguration {
  id: number;
  scoreCount: number;
  scoreIcon: string;
  options: ICompetencyRatingOption[];
  createdAt: Date;
  updatedAt: Date;
}


export interface ICompetencyRatingConfigurationServerSide {
  id: number;
  scoreCount: number;
  scoreIcon: string;
  options: ICompetencyRatingOptionServerSide[];
  createdAt: string;
  updatedAt: string;
}
