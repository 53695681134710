import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompetencyAPIService } from '@app/domain/competency/api/competency-api.service';
import { Competency } from '@app/domain/competency/model/competency.model';
import { InterestAPIService } from '@app/domain/interest/api/interest-api.service';
import { Interest } from '@app/domain/interest/model/interest.model';
import { SkillRatingAPIService } from '@app/domain/skill_rating/api/skill-rating-api.service';
import { CreateSkillRating } from '@app/domain/skill_rating/model/create-skill-rating.model';
import { SkillRatingConfiguration } from '@app/domain/skill_rating/model/skill-rating-configuration.model';
import { SkillRatingType } from '@app/domain/skill_rating/model/skill-rating-type.model';
import { SkillRating } from '@app/domain/skill_rating/model/skill-rating.model';
import { TerminologyEntity } from '@app/domain/terminology/model/terminology-entity.enum';
import { CompanyFeatures } from '@app/models/company-features.model';
import { User } from '@app/models/user/user.model';
import { ButtonType } from '@app/shared/components/inputs/button/button.component';
import { Globals } from '@app/shared/globals/globals';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { NotifyUtils } from '@app/shared/utils/notify.utils';
import { forkJoin } from 'rxjs';

interface PageSkill extends Interest {
  skillRating: SkillRating;
}

interface PageCompetency extends Competency {
  skillRating: SkillRating;
}

const initRatingForm = (skillRatingType?: SkillRatingType, targetEntity?: PageSkill | PageCompetency): FormGroup => {
  const formGroup = new FormGroup({
    skillRatingType: new FormControl(SkillRatingType.SKILL, [ Validators.required ]),
    targetEntityId: new FormControl(null, [ Validators.required ]),
    scoreValue: new FormControl(null, [ Validators.required ]),
    scoreComment: new FormControl(null)
  });

  if (skillRatingType) {
    formGroup.controls.skillRatingType.setValue(skillRatingType);
  }

  if (targetEntity) {
    formGroup.controls.targetEntityId.setValue(targetEntity.id);

    if (targetEntity.skillRating) {
      if (targetEntity.skillRating.scoreValue > -1) {
        formGroup.controls.scoreValue.setValue(targetEntity.skillRating.scoreValue);
      }
  
      if (targetEntity.skillRating.scoreComment) {
        formGroup.controls.scoreComment.setValue(targetEntity.skillRating.scoreComment);
      }
    }
  }

  return formGroup;
};

@Component({
  selector: 'app-profile-skills-competencies',
  templateUrl: './profile-skills-competencies.component.html',
  styleUrls: ['./profile-skills-competencies.component.scss']
})
export class ProfileSkillsCompetenciesComponent implements OnInit {

  public readonly eTerminologyEntity = TerminologyEntity;
  public readonly eCompanyFeatures = CompanyFeatures;
  public readonly eSkillRatingType = SkillRatingType;
  public readonly eButtonType = ButtonType;

  @ViewChild('modalRating') modalRating?: ModalComponent;

  @Input() user: User;

  skills: PageSkill[];
  competencies: PageCompetency[];

  skillsVisible: number;
  competenciesVisible: number;

  itemBeingRated?: PageSkill | PageCompetency;
  formRating: FormGroup;

  skillRatingConfiguration: SkillRatingConfiguration;
  competencyRatingConfiguration: SkillRatingConfiguration;

  get userHasRatings(): boolean {
    if (!this.globals.hasFeature(CompanyFeatures.SKILL_RATING)) return false;
    if (!this.user) return false;
    if (this.user.id === this.user.managerId) return false;
    return true;
  }

  get canAddRating(): boolean {
    if (!this.user) return false; 
    if (!this.globals.user) return false;
    if (this.user.id === this.user.managerId) return;
    if (this.globals.user.id !== this.user.managerId) return false;
    return true;
  }

  constructor(
    public globals: Globals,
    public notifyUtils: NotifyUtils,
    private skillRatingAPIService: SkillRatingAPIService,
    private interestAPIService: InterestAPIService,
    private competencyService: CompetencyAPIService
  ) {
    this.skills = [];
    this.competencies = [];

    this.skillsVisible = 8;
    this.competenciesVisible = 8;

    this.formRating = initRatingForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (!this.user) return;
    forkJoin([
      this.competencyService.getForUserId(this.user.id),
      this.interestAPIService.getSkillsForUserId(this.user.id),
      this.skillRatingAPIService.getForUser(this.user.id),
      this.skillRatingAPIService.getConfiguration(),
      this.competencyService.getRatingConfiguration()
    ])
      .toPromise()
      .then(([competencies, skills, ratings, skillRatingConfiguration, competencyRatingConfiguration]) => {
        if (!this.globals.hasFeature(CompanyFeatures.SKILL_RATING)) {
          ratings = [];
        }

        let pageCompetencies = this.parseCompetenciesToPageCompetencies(competencies, ratings);
        pageCompetencies = this.limitRatingsToScale(pageCompetencies, competencyRatingConfiguration) as PageCompetency[];
        pageCompetencies = pageCompetencies
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort(this.sortBySkillRatingScore);
        this.competencies = pageCompetencies;

        // const allSkills = deepClone(skills);
        // if (this.user.position && this.user.position.skills && this.user.position.skills.length > 0) {
        //   const allSkillsIds = allSkills.map(skill => skill.id);
        //   this.user.position.skills.forEach(skill => {
        //     if (allSkillsIds.includes(skill.id)) return;
        //     allSkills.push(skill);
        //   });
        // }
        let pageSkills = this.parseSkillsToPageSkills(skills, ratings);
        pageSkills = this.limitRatingsToScale(pageSkills, skillRatingConfiguration) as PageSkill[];
        pageSkills = pageSkills
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort(this.sortBySkillRatingScore);
        this.skills = pageSkills;

        this.skillRatingConfiguration = skillRatingConfiguration;
        this.competencyRatingConfiguration = competencyRatingConfiguration;
      });
  }

  sortBySkillRatingScore(a: PageSkill | PageCompetency, b: PageSkill | PageCompetency): number {
    if (!a.skillRating && !b.skillRating) return 0;
    if (!a.skillRating) return 1;
    if (!b.skillRating) return -1;

    return b.skillRating.scoreValue - a.skillRating.scoreValue;
  }

  limitRatingsToScale(items: PageSkill[] | PageCompetency[], skillRatingConfiguration: SkillRatingConfiguration): PageSkill[] | PageCompetency[] {
    return items.map(item => {
      if (!item.skillRating) return item;

      if (item.skillRating.scoreValue > (skillRatingConfiguration.scoreCount - 1)) {
        item.skillRating.scoreValue = (skillRatingConfiguration.scoreCount - 1);
      }

      return item;
    });
  }

  parseCompetenciesToPageCompetencies(competencies: Competency[], ratings: SkillRating[]): PageCompetency[] {
    return competencies.map(competency => this.parseCompetencyToPageCompetency(competency, ratings));
  }

  parseSkillsToPageSkills(skills: Interest[], ratings: SkillRating[]): PageSkill[] {
    return skills.map(skill => this.parseSkillToPageSkill(skill, ratings));
  }

  parseCompetencyToPageCompetency(competency: Competency, ratings: SkillRating[]): PageCompetency {
    const output: PageCompetency = competency as PageCompetency;

    if (output) {
      output.skillRating = ratings.find(rating => rating.skillRatingType === SkillRatingType.COMPETENCY && rating.targetEntityId === competency.id);
    }

    return output;
  }

  parseSkillToPageSkill(skill: Interest, ratings: SkillRating[]): PageSkill {
    const output: PageSkill = skill as PageSkill;

    if (output) {
      output.skillRating = ratings.find(rating => rating.skillRatingType === SkillRatingType.SKILL && rating.targetEntityId === skill.id);
    }

    return output;
  }

  showMoreSkills(): void {
    this.skillsVisible += 8;
  }

  showMoreCompetencies(): void {
    this.competenciesVisible += 8;
  }

  startRatingSkill(skill: PageSkill): void {
    this.formRating = initRatingForm(SkillRatingType.SKILL, skill);
    this.itemBeingRated = skill;
    this.showModal();
  }

  startRatingCompetency(competency: PageCompetency): void {
    this.formRating = initRatingForm(SkillRatingType.COMPETENCY, competency);
    this.itemBeingRated = competency;
    this.showModal();
  }

  showModal(): void {
    if (!this.modalRating) return;
    this.modalRating.show();
  }

  hideModal(): void {
    if (!this.modalRating) return;
    this.modalRating.hide();
  }

  cancelRating(): void {
    this.formRating = initRatingForm();
    this.itemBeingRated = undefined;
  }

  saveRating(): void {
    if (this.formRating.invalid) return;

    const createDto: CreateSkillRating = {
      ...this.formRating.value,
      targetUserId: this.user.id
    };

    this.skillRatingAPIService.create(createDto).toPromise().then(() => {
      this.notifyUtils.notify('Rating saved successfully');
      this.getData();
      this.hideModal();
    });
  }
}
