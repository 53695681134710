<ng-container *ngIf="user">
  <div class="container-profile-skills-competencies">

    <div class="area-skills">
      <h4 class="standard-heading">{{globals.getTerminology(eTerminologyEntity.SKILL_PLURAL)}}</h4>

      <ng-container *ngIf="skills.length > 0; else templateNoSkills;">
        <div class="list-skills-competencies">
          <ng-container *ngFor="let skill of skills; index as index;">
            <ng-container *ngIf="index < skillsVisible">
              <app-skill-rating-display [entity]="skill" [skillRating]="skill.skillRating"
                [skillRatingConfiguration]="skillRatingConfiguration" [showScore]="userHasRatings"
                [canAddRating]="canAddRating" (onStartRatingSkill)="startRatingSkill(skill)"></app-skill-rating-display>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="skills.length > skillsVisible">
          <div class="prompt-show-more" (click)="showMoreSkills()">
            <span>Show more...</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #templateNoSkills>
        <app-empty-state [height]="'100px'"
          [message]="user.firstName + ' has not selected any ' + globals.getTerminology(eTerminologyEntity.SKILL_PLURAL)"></app-empty-state>
      </ng-template>
    </div>

    <div class="area-competencies">
      <h4 class="standard-heading">{{globals.getTerminology(eTerminologyEntity.COMPETENCY_PLURAL)}}</h4>

      <ng-container *ngIf="competencies.length > 0; else templateNoCompetencies;">
        <div class="list-skills-competencies">
          <ng-container *ngFor="let competency of competencies; index as index;">
            <ng-container *ngIf="index < competenciesVisible">
              <app-skill-rating-display [entity]="competency" [skillRating]="competency.skillRating"
                [skillRatingConfiguration]="competencyRatingConfiguration" [showScore]="userHasRatings"
                [canAddRating]="canAddRating"
                (onStartRatingSkill)="startRatingCompetency(competency)"></app-skill-rating-display>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="competencies.length > competenciesVisible">
          <div class="prompt-show-more" (click)="showMoreCompetencies()">
            <span>Show more...</span>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #templateNoCompetencies>
        <app-empty-state [height]="'100px'"
          [message]="user.firstName + ' does not have any ' + globals.getTerminology(eTerminologyEntity.COMPETENCY_PLURAL) + ' related to their role'"></app-empty-state>
      </ng-template>
    </div>

    <ng-container *ngIf="userHasRatings && canAddRating">
      <app-modal (modalHidden)="cancelRating()" #modalRating>
        <div class="modal-skill-rating" *ngIf="formRating.controls.skillRatingType.value">
          <div class="modal-heading">
            <ng-container [ngSwitch]="formRating.controls.skillRatingType.value">
              <ng-container *ngSwitchCase="eSkillRatingType.SKILL">
                <h4 class="standard-heading">Rate {{globals.getTerminology(eTerminologyEntity.SKILL)}}</h4>
                <div class="standard-description">Choose a score to assign to this
                  {{globals.getTerminology(eTerminologyEntity.SKILL)}}</div>
              </ng-container>
              <ng-container *ngSwitchCase="eSkillRatingType.COMPETENCY">
                <h4 class="standard-heading">Rate {{globals.getTerminology(eTerminologyEntity.COMPETENCY)}}</h4>
                <div class="standard-description">Choose a score to assign to this
                  {{globals.getTerminology(eTerminologyEntity.COMPETENCY)}}</div>
              </ng-container>
            </ng-container>
          </div>

          <div class="text-center" *ngIf="itemBeingRated">
            <h5 class="standard-heading">{{itemBeingRated.name}}</h5>
          </div>

          <ng-container [ngSwitch]="formRating.controls.skillRatingType.value">
            <ng-container *ngSwitchCase="eSkillRatingType.SKILL">
              <ng-container *ngIf="skillRatingConfiguration">
                <app-star-scale [starFontSize]="'32px'" [formControl]="formRating.controls.scoreValue"
                  [starCount]="skillRatingConfiguration.scoreCount" [iconUsed]="skillRatingConfiguration.scoreIcon"
                  [optionMetadata]="skillRatingConfiguration.options" [showTooltips]="true"></app-star-scale>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="eSkillRatingType.COMPETENCY">
              <ng-container *ngIf="competencyRatingConfiguration">
                <app-star-scale [starFontSize]="'32px'" [formControl]="formRating.controls.scoreValue"
                  [starCount]="competencyRatingConfiguration.scoreCount"
                  [iconUsed]="competencyRatingConfiguration.scoreIcon"
                  [optionMetadata]="competencyRatingConfiguration.options" [showTooltips]="true"></app-star-scale>
              </ng-container>
            </ng-container>
          </ng-container>

          <app-editor-response [height]="'200px'" [columnIndent]="false"
            [formControl]="formRating.controls.scoreComment"></app-editor-response>

          <div class="text-right">
            <!-- <app-button [type]="eButtonType.GRAY_INVERTED" (click)="cancelRating()">Cancel</app-button>
            <span class="m-right-5"></span> -->
            <app-button [type]="eButtonType.GREEN" (click)="saveRating()">Save</app-button>
          </div>
        </div>
      </app-modal>
    </ng-container>

  </div>
</ng-container>